import logo from '../assets/images/J_D-Logo_15_-WHITE-BG-Transparent-bg.png';
import InstagramIcon from '../assets/images/instagram.png';
import FacebookIcon from '../assets/images/facebook-app-symbol.png';
import TwitterIcon from '../assets/images/twitter.png';
import LinkedInIcon from '../assets/images/linkedin.png';
// import tiktokIcon from '../assets/images/tik-tok.png';
import { FaPhone } from "react-icons/fa6";
import { HiOutlineMail } from "react-icons/hi";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { Link } from 'react-router-dom';

const Footer = () => {

    // const scrollToTop = () => {
    //     window.scrollTo({
    //       top: 0,
    //       behavior: 'smooth', 
    //     });
    //   };
    

    
    return (
        <footer className="footer text-white p-12 max-sm:p-0 pt-0">

            <div className="flex flex-col divide-y gap-5">
                <div className='p-5 max-sm:order-1 max-sm:hidden'>
                    <Link to='/'>
                <img src={logo} alt="logo" width={200}></img>
                    </Link>
                </div>
                <div className="flex row justify-between p-5 max-sm:flex-wrap max-md:flex-col md:flex-col lg:flex-row">
                    <div className='flex flex-col gap-5 max-sm:hidden'>
                        
                        <h1 className='text-3xl text-white font-bold text-start'>
                            Browse Active <br /> and Sold listings<br /> like never before
                            Explore
                        </h1>
                        <ul className='flex gap-1 items-center'>
                            <li><button className='p-3 rounded-full w-28 xl:w-20 xl:p-2 text-white hover:text-white border border-white'>Log in</button></li>
                            <li><button className='p-3 bg-white text-black rounded-full w-28 xl:w-20 xl:p-2'>Join</button></li>
                        </ul>
                    </div>
                    <div className='grid grid-cols-2 xl:divide-x max-sm:grid-cols-1'>
                    <div className='flex row p-3  gap-7 flex-wrap max-sm:flex-row max-sm:gap-10 max-sm:justify-between md:justify-between  '>
                        <ul className='text-base font-semibold text-start'>
                            <li className='text-xl hover:text-gold-400 transition-colors duration-300 '>Explore</li>
                             <Link to="/buy">
                            <li  className='mt-4 hover:text-gold-400 transition-colors duration-300'>Buy</li>
                             </Link>
                             <Link to="/sell">
                            <li className='hover:text-gold-400 transition-colors duration-300'>Sell</li>
                             </Link>
                             <Link to="/ourTeam">
                            <li className='hover:text-gold-400 transition-colors duration-300'>Our Team</li>
                             </Link>

                             <Link to="/ourstory">

                            <li className='hover:text-gold-400 transition-colors duration-300'>Our Story</li>
                            </Link>
                        </ul>
                        <ul className='text-base font-semibold text-start'>
                            <li className='text-xl hover:text-gold-400 transition-colors duration-300'>Learn More</li>
                            <Link to="/blog">
                            <li className='mt-4 hover:text-gold-400 transition-colors duration-300'>Blog</li>
                            </Link>
                            <Link to="/contact">
                            <li className='hover:text-gold-400 transition-colors duration-300'>Contact Us</li>
                            </Link>
                        </ul>
                        <ul className='text-base font-semibold text-start max-sm:flex max-sm:flex-col gap-4'>
                            <li className='text-xl hover:text-gold-400 transition-colors duration-300'>My Account</li>
                            <div className='max-sm:flex max-sm:text-xs gap-8'>
                            <li className='mt-4 max-sm:mt-0 hover:text-gold-400 transition-colors duration-300'>Profile</li>
                            <li className='hover:text-gold-400 transition-colors duration-300'>Favourites</li>
                            <li className='hover:text-gold-400 transition-colors duration-300'>Watched Listings</li>
                            <li className='hover:text-gold-400 transition-colors duration-300'>Watched Areas</li>
                            </div>
                        </ul>

                    </div>
                    <div>
                        <ul className='text-start text-base font-semibold p-3'>
                            <li className='text-xl hover:text-gold-400 transition-colors duration-300'>Get in Touch</li>
                            <li className='mt-4 flex gap-2 items-center hover:text-gold-400 transition-colors duration-300'><span><FaPhone /></span>7879654644</li>
                            <li className='flex  gap-2 items-center hover:text-gold-400 transition-colors duration-300'><span><FaPhone /></span> 59876946656</li>
                            <li className='flex flex-wrap gap-2 items-center hover:text-gold-400 transition-colors duration-300'><span><HiOutlineMail /></span>INFO@JOHNSTONANDDANIEL.COM</li>
                            <li className='flex gap-2 items-center hover:text-gold-400 transition-colors duration-300'><span><HiOutlineLocationMarker/></span>477 Mount Pleasant Road Toronto, ON, M4S 2L9</li>
                        </ul>
                    </div>
                    </div>
          
                </div>
                <div className='grid grid-cols-4 '>
                        <div className='mt-4'>
                            <h2 className='text-xl hover:text-gold-400 transition-colors duration-300 mb-3'>Toronto</h2>
                            <li className='list-none'>477 MOUNT PLEASANT ROAD</li>
                            <li className='list-none'>(416) 489-2121</li>
                        </div>
                        <div className='mt-4'>
                            <h2 className='text-xl hover:text-gold-400 transition-colors duration-300 mb-3'>Oakville</h2>
                            <li className='list-none'>326 LAKESHORE ROAD EAST</li>
                            <li className='list-none'>(905) 845-1223</li>
                        </div>
                        <div className='mt-4'>
                            <h2 className='text-xl hover:text-gold-400 transition-colors duration-300 mb-3'>Port Carling*</h2>
                            <li className='list-none'>118 MEDORA STREET</li>
                            <li className='list-none'>(705) 765-6855</li>
                        </div>
                        <div className='mt-4'>
                            <h2 className='text-xl hover:text-gold-400 transition-colors duration-300 mb-3'>Bala*
                            </h2>
                            <li className='list-none'>3133-1 MUSKOKA ROAD</li>
                            <li className='list-none'>(705) 762-3410

</li>
                        </div>




                    </div>
                <div className='flex flex-col p-3 max-sm:order-2'>
                    <div className='flex flex-row items-center justify-between max-sm:flex-col max-sm:gap-4'>
                      <p className='font-semibold max-sm:order-1 max-sm:text-lg'> © 2024 Copyright reserved </p>
                      <div className='flex flex-row gap-4 max-sm:order max-sm:gap-12'>
                      <a className='hover:text-gold-400 transition-colors duration-300' href='https://www.instagram.com/johnstonanddaniel/' target="_blank"><img  src={InstagramIcon} alt='instagram' width={20} ></img></a>  
                        
                     <a href='https://www.facebook.com/JohnstonandDaniel/' target="_blank"> <img className='hover:text-gold-400 transition-colors duration-300' src={FacebookIcon} alt='FacebookIcon' width={20} ></img></a>   
                     <a href='https://x.com/i/flow/login?redirect_after_login=%2Fjohnstondaniel' target="_blank"><img className='hover:text-gold-400 transition-colors duration-300' src={TwitterIcon} alt='TwitterIcon' width={20} ></img></a>  
                     <a href='https://www.linkedin.com/company/johnston-and-daniel/' target="_blank"> <img className='hover:text-gold-400 transition-colors duration-300' src={LinkedInIcon} alt='LinkedInIcon' width={20} ></img></a> 
                     
                      </div>
                      <div className='flex flex-row font-semibold p-5 gap-6 max-sm:order-2 max-sm:p-0' >
                        <Link to="/privacy-policy">
                        <li className='hover:text-gold-400 transition-colors duration-300 list-none' >Privacy Policy</li>
                        </Link>
                       <Link to="/Accessibility">
                        <li className='hover:text-gold-400 transition-colors duration-300 list-none' >Accessibility</li>
                       </Link>

                      </div>
                    </div>
                    <div>
                        {/* <p className='text-start text-gray-300 max-sm:text-xs'>We are committed to providing the most personalized real estate services 
                            from listing to close. We have exceptional results which are confirmed by 
                            the number of clients we serve. Discover our selection of income-generating 
                            rental properties and commercial ventures.</p> */}

<p className='text-start text-[14px]' style={{fontFamily:'"Roboto","sans-serif"'}}>© 2020 Johnston &amp; Daniel, A Division of Royal LePage Real Estate Services Ltd., Brokerage. All rights reserved. Not intended to solicit buyers or sellers, landlords or tenants currently under contract. The trademarks REALTOR®, REALTORS® and the REALTOR® logo are controlled by The Canadian Real Estate Association (CREA) and identify real estate professionals who are members of CREA. The trademarks MLS®, Multiple Listing Service® and the associated logos are owned by CREA and identify the quality of services provided by real estate professionals who are members of CREA. REALTOR® contact information provided to facilitate inquiries from consumers interested in Real Estate services.&nbsp;</p>
<p className='mt-5 text-start text-[14px] font-roboto'>*Johnston &amp; Daniel Rushbrooke Realty Ltd. | Independently owned and operated. Any services or products provided by independently owned and operated franchisees are not provided by, affiliated with or related to Johnston &amp; Daniel, A Division of Royal LePage Real Estate Services Ltd., Brokerage, nor any of its affiliated companies.</p>
                    </div>
                </div>
            </div>

        </footer>
    );
};

export default Footer