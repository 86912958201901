import React, { useEffect } from 'react';
import { useNavigate} from "react-router-dom"
import img1 from '../assets/images/Body-First-Image-Top-Left-1.jpg'
import img2 from '../assets/images/Body-Second-Image-Bottom-Right-1.jpg';
import leaderImg1 from '../assets/images/Anne-Young-320xAUTO.fit_.jpg';
import leaderImg2 from '../assets/images/Phil-Soper-1-320xAUTO.fit_.jpg';
import leaderImg3 from '../assets/images/Yolevski-Karen-Feb-14-2023-headshot-WEB-06-e1704992414790-525x328.webp';
import leaderImg4 from '../assets/images/SALAZAR-JD-BW-NO-BG-320xAUTO.fit_.png';
import leaderImg5 from '../assets/images/John-O-Rourke-320xAUTO.fit_.jpg';
import leaderImg6 from '../assets/images/Shelley-Reville-320xAUTO.fit_.jpg';
import bgImg from '../assets/images/image-box-large-1-1-1.webp'
import getInTouchImg from '../assets/images/agent-1-1-1.webp';
import HeaderImg from "../assets/images/Header-Image-1.webp"
import HomeImage from "../assets/images/main-home-bg-img-1-1-1 (1).webp"
import { useState } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import CountUp from 'react-countup';
import '../App.css'
const OurStory = () => {

    const fontStyle = {
        h3: {
          fontSize: "45px",
            fontFamily: '"Bodoni Moda", "Sans-serif"',
            color:"black",
            lineHeight: "1.5em",

        },
        h2: {
            color:" #FFFFFF",
            fontFamily: '"Bodoni Moda", "Sans-serif"',
            fontSize: "67px",
            fontWeight:" 400",
            lineHeight: "1.1em",
            textShadow: "0px 0px 10px rgba(0, 0, 0, 0.3)",
          },
      };
    const navigate = useNavigate()

    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        city: "",
        state: "",
        email: "",
        mobile: "",
        message: "",
        consent: false,
    });

    const [errors, setErrors] = useState({
        firstName: "",
        lastName: "",
        city: "",
        state: "",
        email: "",
        mobile: "",
        message: "",
        consent: "",
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: type === "checkbox" ? checked : value,
        }));
    };

    const validateForm = () => {
        let formErrors = {};
        let isValid = true;

        // Name validation
        if (!formData.firstName) {
            formErrors.firstName = "First name is required";
            isValid = false;
        }
        if (!formData.lastName) {
            formErrors.lastName = "Last name is required";
            isValid = false;
        }

        // City and State validation
        if (!formData.city) {
            formErrors.city = "City is required";
            isValid = false;
        }
        if (!formData.state) {
            formErrors.state = "State is required";
            isValid = false;
        }

        // Email validation
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        if (!formData.email) {
            formErrors.email = "Email is required";
            isValid = false;
        } else if (!emailPattern.test(formData.email)) {
            formErrors.email = "Please enter a valid email address";
            isValid = false;
        }

        // Mobile validation
        if (!formData.mobile) {
            formErrors.mobile = "Mobile number is required";
            isValid = false;
        } else if (formData.mobile.length !== 10) {
            formErrors.mobile = "Mobile number must be 10 digits";
            isValid = false;
        } else if (!/^\d{10}$/.test(formData.mobile)) {
            formErrors.mobile = "Mobile number must contain only digits";
            isValid = false;
        }

        // Message validation
        if (!formData.message) {
            formErrors.message = "Message is required";
            isValid = false;
        }

        // Consent validation
        if (!formData.consent) {
            formErrors.consent = "You must consent to the GDPR terms";
            isValid = false;
        }

        setErrors(formErrors);
        return isValid;
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Validate form
        if (validateForm()) {
            // Send form data or handle form submission here
            console.log("Form data submitted:", formData);

            setFormData({
                firstName: "",
                lastName: "",
                city: "",
                state: "",
                email: "",
                mobile: "",
                message: "",
            })
        }

    };

   
       
    const members = [
        { name: "Anne Young", role: "VICE PRESIDENT, DIVISION MANAGER", phone:"246.380.7500", images: leaderImg1, id: 1 ,email:"AnneYoung.com"},
        { name: "Phil Soper", role: "PRESIDENT & CEO, ROYAL LEPAGE", phone:"456.370.7500", images: leaderImg2, id: 2 ,email:"PhilSoper.com"},
        { name: "Karen Yolevski", role: "CHIEF OPERATING OFFICER, CORPORATE BROKERAGES", phone:"416.380.7500", images: leaderImg3, id: 3,email:"KarenYolevski.com" },
        { name: "Melissa Salazar", role: "DIRECTOR MARKETING & CLIENT SOLUTIONS", phone:"416.390.7500", images: leaderImg4, id: 4 ,email:"MelissaSalazar.com"},
        { name: "John O'Rourke", role: "PRESIDENT, RUSHBROOKE REALTY", phone:"356.380.7500", images: leaderImg5, id: 5 ,email:"JohnO'Rourke.com"},
        { name: "Shelley Reville", role: "BROKER-OF-RECORD, RUSHBROOKE REALTY", phone:"768.370.7500", images: leaderImg6, id: 6 ,email:"ShelleyReville.com"},
    ];
    
    const [allmember, setMember] = useState(members);
    console.log(allmember);
  
    const results = [
        { value: "$100M", description: "Current Listing Volume" },
        { value: "$400M", description: "Total Sold 2020 - 2024" },
        { value: "$2B", description: "Lifetime Sales Volume" },
    ];

 
    const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.5 });
    const controls = useAnimation();

    useEffect(() => {
        if (inView) {
            controls.start({ opacity: 1, y: 0 });
        }
    }, [inView, controls]);
    return (
        <div className=' bg-gray-100'>
            <div className="relative bg-cover bg-center animate-fadeIn h-[630px] flex items-center mb-12" style={{
                backgroundImage:
                    `url(${HeaderImg})`,
            }}>
                <div className="absolute inset-0 bg-black bg-opacity-50"></div>

                <div className="relative text-center w-full">


                    <h1 className='text-lg text-white'>70 YEARS OF BESPOKE SERVICE BEHIND YOUR LISTING</h1>
                    <h3 style={fontStyle.h2} className=''>About Johnston & Daniel</h3>

                </div>


            </div>

            <div className='' style={{backgroundImage:`url(${HomeImage})`}}>
                <div className="flex flex-col md:flex-row items-center md:items-start min-h-screen bg-pink-50 px-28 pt-10 max-sm:px-5">
                    {/* Text Section */}
                    <div className="md:w-1/2 mt-10 p-10 pl-10 pr-12 text-start bg-white rounded-xl max-sm:p-0">
                        <h1 style={fontStyle.h3} className=" md:text-3xl ">
                            From breakaway idea to
                             global luxury property 
                            nexus.
                        </h1>
                        <p className="mt-6 text-gray-600 leading-relaxed text-lg">
                            An awakening began in 1950. Post-war Toronto was finding its feet,
                            transforming from an undistinguished port of call into a powerhouse
                            of business, finance and manufacturing. This, and its many
                            geo-demographic advantages, attracted new wealth, investment and people.
                            And they all needed somewhere to live. A handful of seasoned real estate
                            professionals sensed this growing luxury niche was being underserved and
                            set out to disrupt the status quo with something much better.
                        </p>
                        <p className="mt-4 text-gray-600 leading-relaxed text-lg">
                            Johnston & Daniel: A vision bespoke. Our founders crafted an approach
                            that had never been tried in Toronto: To focus on fine homes and a
                            discriminating clientele, delivering superior results through a blend
                            of tailored service and “inside” advice. They also understood that
                            better service alone would not suffice.
                        </p>
                    </div>

                    {/* Image Section */}
                    <div className="md:w-1/2">
                        <img
                            src={img1} // Replace with the actual image path
                            alt="Luxury Kitchen"
                            className="w-full h-[700px] object-cover rounded-xl"
                        />
                    </div>
                </div>
            </div>
        <section style={{backgroundImage:`url(${HomeImage})`, backgroundSize: 'contain'}}>
            <div className='mt-7 ' >
                <div className="flex flex-col md:flex-row items-center md:items-start min-h-screen bg-pink-50 px-28 pt-10 max-sm:px-5">

                    {/* Image Section */}
                    <div className="md:w-1/2">
                        <img
                            src={img2} // Replace with the actual image path
                            alt="Luxury Kitchen"
                            className="w-full h-[700px] object-cover rounded-xl"
                        />
                    </div>
                    {/* Text Section */}
                    <div className="md:w-1/2 mt-10 p-10 text-start bg-white rounded-xl max-sm:p-0">
                        <h1 style={fontStyle.h3} className="leading-tight text-gray-800">
                            Relationships beyond the transactional.
                        </h1>
                        <p className="mt-6 text-gray-600 leading-relaxed text-lg">
                            J&D Realtors, they decided, must possess a sixth sense, a ground-state awareness of home sellers’ and buyers’ requirements and lifestyles – extending well beyond the deal. Anticipation over response. Professional-grade commitment over a hobbyist mentality. And above all, wisdom, diligence and integrity beyond reproach.
                        </p>
                        <p className="mt-4 text-gray-600 leading-relaxed text-lg">
                            New opportunities, ambitious women. So they hired and trained a team of shrewd local women (and a few good men) to become this new breed of Realtor – after all, women had always been the final arbiters in residential transactions. The idea caught fire. J&D Realtors excelled. Word-of-mouth did the rest.
                        </p>
                        <p className="mt-4 text-gray-600 leading-relaxed text-lg">
                            Two Canadian real estate titans unite. In 1994, Johnston & Daniel was acquired by Royal LePage, enabling increased dedication to service, more sophisticated marketing tools and a wider service offering. Today, J&D’s reach and influence connects our clients to real estate opportunities worldwide.
                        </p>
                    </div>


                </div>
            </div>

        </section>

            <div className=''>
                <div className=' mb-10'>
                    <h1 style={fontStyle.h3} className=' p-5'>Leadership</h1>
                    <p className='text-gray-600 leading-relaxed text-lg px-96 max-sm:px-0'>Our leadership team collectively boasts decades of industry experience and is committed to enhancing the brokerage experience through professionalism, growth of our people and upholding the virtue that is the Standard Of Excellence®.</p>
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 px-28 mt-4 max-sm:px-5" >
                    {members.map((member, index) => (
                        <div
                            key={index}
                            onClick={() => navigate(`/ourstory/${member.name}`,{state:{allmember}})}
                            className="relative bg-black text-white rounded-lg overflow-hidden shadow-md hover:shadow-xl transition-shadow duration-300"
                        >
                            <div className="absolute inset-0 bg-black opacity-30"></div>

                            {/* Replace the background image below with actual image URLs */}
                            <div
                                className="h-[27rem]  bg-cover bg-center"
                                style={{
                                    backgroundImage: `url(${member.images})`,
                                }}
                            ></div>
                            <div className="absolute bottom-4 left-4 text-start">
                                <h3 className="text-lg font-bold">{member.name}</h3>
                                <p className="text-sm">{member.role}</p>
                            </div>
                        </div>
                    ))}

                </div>
            </div>

            <div className='mt-32' ref={ref}>
                <div
                    className="relative bg-cover bg-center h-[500px] flex items-center max-sm:h-[800px]"
                    style={{
                        backgroundImage:
                            `url(${bgImg})`, // Replace with your image URL
                    }}
                >
                    <div className="absolute inset-0 bg-black bg-opacity-50"></div>
                    <div className="relative text-center w-full">
                        <h2 style={fontStyle.h2} className="text-white   tracking-wide mb-12">
                            OUR RESULTS
                        </h2>
                        <div className="grid grid-cols-3 gap-20 items-start text-white max-sm:grid-cols-1">
                            <div className="text-center">
                                <motion.h2
                                    className="text-6xl font-bold text-gold-400"
                                    initial={{ opacity: 0, y: 50 }}
                                    animate={controls}
                                    style={{ 
                                        fontFamily: '"Bodoni Moda", "Sans-serif"',
                                        fontSize: "67px",
                                        fontWeight:" 400",}}
                                >
                                    ${inView && <CountUp start={0} end={100} duration={3.5} />}M
                                </motion.h2>
                                <p  style={{fontSize:"22px", fontWeight:"400px", fontFamily:'"Roboto Slab","Sans-serif"'}} className=" mt-2">Current Listing Volume</p>
                            </div>

                            <div className="text-center">
                            <motion.h2
                                    className="text-6xl font-bold text-gold-400"
                                    initial={{ opacity: 0, y: 50 }}
                                    animate={controls}
                                    style={{ 
                                        fontFamily: '"Bodoni Moda", "Sans-serif"',
                                        fontSize: "67px",
                                        fontWeight:" 400",}}
                                >
                                    ${inView && <CountUp start={300} end={400} duration={3.5} />}M
                                </motion.h2>
                                <p style={{fontSize:"22px", fontWeight:"400px", fontFamily:'"Roboto Slab","Sans-serif"'}} className="text-lg mt-2">Total Sold 2020 - 2024</p>
                            </div>
                            <div className="text-center">
                            <motion.h2
                                    className="text-6xl font-bold text-gold-400"
                                    initial={{ opacity: 0, y: 50 }}
                                    animate={controls}
                                    style={{ 
                                        fontFamily: '"Bodoni Moda", "Sans-serif"',
                                        fontSize: "67px",
                                        fontWeight:" 400",}}
                                >
                                    ${inView && <CountUp start={0} end={2} duration={3.5} />}B
                                </motion.h2>
                                <p style={{fontSize:"22px", fontWeight:"400px", fontFamily:'"Roboto Slab","Sans-serif"'}} className="text-lg mt-2">Lifetime Sales Volume</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='mt-7 mb-20 relative'>
    <div className="absolute -top-7 w-[70%] h-[902px] bg-Stone-100 flex right-0"></div>
    <div className="flex flex-col md:flex-row items-center md:items-start min-h-screen bg-pink-50 px-28 pt-10 max-sm:px-5">

        {/* Image Section */}
        <div className="md:w-1/2 z-10">
            <img
                src={getInTouchImg} // Replace with the actual image path
                alt="Luxury Kitchen"
                className="w-full h-[713px] object-cover rounded-xl mt-10"
            />
        </div>
                    {/* Text Section */}
                    <div className="md:w-1/2 mt-10 p-10 text-start bg-white rounded-xl z-10 max-sm:p-0">
            <div className="bg-white p-8 rounded-lg w-full border  max-w-lg">
                <h1 style={{ fontSize: "40px",fontFamily:"'Bodoni Moda','Sans-serif'" ,fontWeight:"400px", textTransform:"capitalize"}} className="text-gray-800 mb-6">
                    Get In Touch!
                </h1>
                <form className="space-y-4 bg-gray-100 p-5 rounded-lg border" onSubmit={handleSubmit}>
                    <div className="grid grid-cols-2 gap-4">
                        <div>
                            <input
                                type="text"
                                name="firstName"
                                value={formData.firstName}
                                onChange={handleChange}
                                placeholder="Name *"
                                // className="border border-gray-300 rounded-lg p-2 w-full"
                                className={`flex w-[100%] border p-2 rounded-lg outline-none text-gray-700 focus:bg-gray-200 ${
                                    errors.firstName ? "border-Red-700" : ""
                                  }`}
                            />
                            {errors.firstName && (
                                <p className="text-Red-700 text-sm">{errors.firstName} *</p>
                            )}
                        </div>
                        <div>
                            <input
                                type="text"
                                name="lastName"
                                value={formData.lastName}
                                onChange={handleChange}
                                placeholder="Last Name *"
                                className={` w-[100%] border p-2 rounded-lg outline-none text-gray-700 focus:bg-gray-200 ${
                                    errors.lastName ? "border-Red-700" : ""
                                  }`}
                            />
                            {errors.lastName && (
                                <p className="text-Red-700 text-sm">{errors.lastName} *</p>
                            )}
                        </div>
                    </div>
                    <div className="grid grid-cols-2 gap-4">
                        <div>
                            <input
                                type="text"
                                name="city"
                                value={formData.city}
                                onChange={handleChange}
                                placeholder="City *"
                                className={` w-[100%] border p-2 rounded-lg outline-none text-gray-700 focus:bg-gray-200 ${
                                    errors.city ? "border-Red-700" : ""
                                  }`}
                            />
                            {errors.city && (
                                <p className="text-Red-700 text-sm">{errors.city} *</p>
                            )}
                        </div>
                        <div>
                            <input
                                type="text"
                                name="state"
                                value={formData.state}
                                onChange={handleChange}
                                placeholder="State *"
                                className={` w-[100%] border p-2 rounded-lg outline-none text-gray-700 focus:bg-gray-200 ${
                                    errors.state ? "border-Red-700" : ""
                                  }`}
                            />
                            {errors.state && (
                                <p className="text-Red-700 text-sm">{errors.state} *</p>
                            )}
                        </div>
                    </div>
                    <div>
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            placeholder="Email *"
                            className={` w-[100%] border p-2 rounded-lg outline-none text-gray-700 focus:bg-gray-200 ${
                                errors.email ? "border-Red-700" : ""
                              }`}
                        />
                        {errors.email && (
                            <p className="text-Red-700 text-sm">{errors.email} *</p>
                        )}
                    </div>
                    <div>
                        <input
                            type="text"
                            name="mobile"
                            value={formData.mobile}
                            onChange={handleChange}
                            placeholder="Mobile *"
                            className={` w-[100%] border p-2 rounded-lg outline-none text-gray-700 focus:bg-gray-200 ${
                                errors.mobile ? "border-Red-700" : ""
                              }`}
                        />
                        {errors.mobile && (
                            <p className="text-Red-700 text-sm">{errors.mobile} *</p>
                        )}
                    </div>
                    <div>
                        <textarea
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                            placeholder="Type your message"
                            rows={4}
                            className={` w-[100%] border p-2 rounded-lg outline-none text-gray-700 focus:bg-gray-200 ${
                                errors.message ? "border-Red-700" : ""
                              }`}
                        />
                        {errors.message && (
                            <p className="text-Red-700 text-sm">{errors.message} *</p>
                        )}
                    </div>
                    <div className="  ">
                        <div className='flex  items-center gap-2 '>
                        <input
                            type="checkbox"
                            name="consent"
                            checked={formData.consent}
                            onChange={handleChange}
                            className="w-4 h-4 text-start"
                        />
                        <label htmlFor="consent" className="text-sm text-gray-600">
                            I consent to the GDPR Terms
                        </label>
                        </div>
                        {errors.consent && (
                            <p className="text-Red-700 text-sm">{errors.consent}*</p>
                        )}
                    </div>
                    <button
                        type="submit"
                        className="w-full bg-[#c5a46d] text-white font-semibold py-2 rounded-lg shadow-md hover:bg-[#b0905e] transition"
                    >
                        Send Email
                    </button>
                </form>
            </div>
        </div>


                </div>
            </div>
        </div>
    );
};

export default OurStory;