import React from 'react';
import "../../App.css";

function Accessibility1() {
  return (
    <div className="pt-24 container max-w-screen-lg mx-auto px-4 sm:px-6 md:px-8">
      <div className="mt-11">
        <h1
          className="text-center flex flex-col items-center gap-4 mb-6 font-bold text-[28px] sm:text-[32px] md:text-[36px]"
        >
          Accessibility for Ontarians with Disabilities Act <br className="hidden sm:block" />(AODA)
        </h1>
      </div>
      <div className="single-content flex flex-col gap-4 mb-0">
        <h2 className="text-start font-bold text-customBlue mb-2 text-lg md:text-xl">Accessibility</h2>
        <p className="text-start text-gray-700 text-sm md:text-base leading-relaxed">
          The Accessibility for Ontarians with Disabilities Act was passed in 2005. Its goal is to make Ontario accessible for people with disabilities by 2025. The Accessibility Standard for Customer Service applies to all businesses and organizations in Ontario with one or more employees. As Royal LePage Real Estate Services Ltd. (The Company) strives to provide exceptional customer service to everyone in a dignified and respectful manner, this policy applies to all Canadian Company employees.
        </p>
        <h2 className="text-start font-bold text-customBlue mb-2 text-lg md:text-xl">Accessibility Plans & Policies</h2>
        <p className="text-start text-gray-700 text-sm md:text-base leading-relaxed">
          The Company is committed to treating all people in a way that allows them to maintain their dignity and independence. We believe in integration and equal opportunity. We are committed to meeting the needs of people with disabilities in a timely manner and will do so by preventing and removing barriers to accessibility and meeting accessibility requirements under the Accessibility for Ontarians with Disabilities Act.
        </p>
        <h2 className="text-start font-bold text-customBlue mb-2 text-lg md:text-xl">Alternative Formats</h2>
        <p className="text-start text-gray-700 text-sm md:text-base leading-relaxed">
          If you would like more information on any information contained on this website, or related to the business we operate within, or would like any documents contained on this website in an alternative format, please contact our accessibility department at 416.510.5686 or through email at&nbsp;
        </p>
        <h2 className="text-start font-bold text-customBlue mb-2 text-lg md:text-xl">Accessibility Feedback</h2>
        <p className="text-start text-gray-700 text-sm md:text-base leading-relaxed">
          The Company welcomes your feedback related to the Accessibility of services to persons with disabilities. We are committed to accessibility for persons with disabilities. We do this through the provision of accessible service in the areas of information and communication, facilities, and customer service.
        </p>
        <p className="text-start text-gray-700 text-sm md:text-base leading-relaxed">
          We continuously strive for excellent service and with your feedback, we will continue to build on the quality service that you have come to expect.
        </p>
        <p className="text-start text-gray-700 text-sm md:text-base leading-relaxed">
          We invite your accessibility feedback, comments, concerns, and suggestions via:
        </p>
        <div className="pl-6 list-disc text-gray-700 text-sm md:text-base">
          <p><span className="font-bold">E-mail:</span> accessibility@example.com</p>
          <p><span className="font-bold">Telephone:</span> 416.510.5686</p>
          <p><span className="font-bold">Mail:</span> 39 Wynford Drive, 1st Floor, Toronto, ON M3C 3K5</p>
        </div>
      </div>
    </div>
  );
}

export default Accessibility1;
