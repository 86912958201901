import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IoBedOutline } from "react-icons/io5";
import { LiaBathSolid } from "react-icons/lia";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import { Tooltip } from 'react-tooltip';
import { BiArea } from "react-icons/bi";
import { getListings,getSearchListings } from '../service';
import LoaderCard from './loader';
import { GrLocation } from "react-icons/gr";



const ListView = ({ 
    getSearchQuery,
    handleSearchTrue,
    propertyType, 
    location, 
    priceRange, 
    minPrice, 
    maxPrice, 
    beds, 
    baths, 
    mlsNumber,
    status,
    purchaseType,
    filterTrue,
    sortType
 }) => {
    const [listData, setListData] = useState([]);
    console.log(listData,"listData");
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    console.log(loading,"loading");
    const [totalPages, setTotalPages] = useState(0);
    const itemsPerPage = 12;
   

    useEffect(() => {
        fetchData();
    }, [currentPage, propertyType, baths, beds,minPrice,maxPrice,status,purchaseType,location,sortType]);

    useEffect(() => {
        // getSearchQueryval();
    }, [handleSearchTrue,getSearchQuery]);



    

    const getSearchQueryval = async () => {
        try {
            const searchQuery = await getSearchListings(mlsNumber);
          
            setListData(searchQuery.data.listings);
            

        } catch (error){
            console.error('Error fetching data:', error);
        }
    };

    console.log(getSearchQueryval,"getSearchQueryval");



    const fetchData = async () => {
        let filterQuery = '';
        console.log('minlist',minPrice)

        if(location){
            filterQuery += `&location=${location}`;
        }

        if (propertyType) {
            console.log('prop...',propertyType);
            if(propertyType === 'All'){
                filterQuery += '';
            }else{

            filterQuery += `&propertyType=${propertyType}`;
            }
        }
        if (baths) {
            filterQuery += `&baths=${baths}`;
        }
        if (beds) {
            
            filterQuery += `&beds=${beds}`;
        };
        if (minPrice) {
            filterQuery += `&minPrice=${minPrice}`;
        }
        if (maxPrice) {
            filterQuery += `&maxPrice=${maxPrice}`;
        }
        if (mlsNumber) {
            filterQuery += `?search=${mlsNumber}`;
        }
        if(status){
            filterQuery += `&status=${status}`;
    
        }if(purchaseType){
            filterQuery += `&purchaseType=${purchaseType}`;
        } if(sortType) {
            filterQuery += `&sortType=${sortType}`
        }

        setLoading(true);

        try {
            const dataList = await getListings(currentPage, itemsPerPage, filterQuery);
            const totalPages = dataList.data.totalPages;
            const totalCount = dataList.data.totalCount;
            console.log(dataList);
            setListData(dataList.data.listings);
            setTotalPages(totalPages);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };
    const formatPrice = (price) => {
        return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 0,
        }).format(price);
      };
      const formatOpenHouseDate = (openHouse) => {
        const date = new Date(openHouse.date);
        const day = String(date.getDate()).padStart(2, '0');  // Get day with leading zero
        const month = date.toLocaleString('default', { month: 'short' });  // Get abbreviated month
        
        // Format start and end times, converting them to lowercase
        const startTime = openHouse.startTime.toLowerCase();
        const endTime = openHouse.endTime.toLowerCase();
    
        return `${day} ${month} ${startTime} - ${endTime}`;
    }
    
    

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = listData.slice(indexOfFirstItem, indexOfLastItem);

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const renderPageNumbers = () => {
        const pageNumbers = [];
        const maxPagesToShow = 5;
        const startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
        const endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(
                <button
                    key={i}
                    className={`w-12 rounded-full text-sm ${i === currentPage ? 'bg-[#545350] text-white' : 'bg-gray-200'}`}
                    onClick={() => handlePageClick(i)}
                >
                    {i}
                </button>
            );
        }

        return pageNumbers;
    };

    console.log(listData ,"ListDaaata")


    return (
        <>
      
            <div className='grid gap-y-16 grid-cols-4 gap-7 p-7 max-sm:grid-cols-1 max-md:grid-cols-2 xl:grid-cols-3 xl:pt-16 xl2:grid-cols-4 xl2:pt-6 max-lg:grid-cols-2 2xl:grid-cols-4 max-xl:grid-cols-3 place-items-center'>
  {loading ? (
    // Render loader cards while loading
    Array.from({ length: itemsPerPage }).map((_, index) => (
      <LoaderCard key={index} />
    ))
  ) : listData.length > 0 ? (
    listData.map((item, index) => (
      <div key={index} className='w-[370px] max-2xl:w-[330px] max-sm:w-full rounded-xl shadow-lg'>
        <div className='h-[500px] max-2xl:h-[400px] relative bg-cover bg-center rounded-2xl overflow-hidden'>
          <img
            src={item.images[0]}
            alt='home'
            className='rounded-2xl w-full h-full cursor-pointer object-cover transform hover:scale-105'
            style={{
              transition: 'all 1s cubic-bezier(.3,1,.35,1) 0s, transform .5s ease',
            }}
            onClick={() => window.open(`/property/${item.mlsNumber}`, '_blank')}
          />
          {status === 'O' && (  
            <div className='absolute top-4 p-1 bg-white ml-4 rounded-md'>
              <p className='text-sm text-gray-700'>{`Open house: ${formatOpenHouseDate(item.openHouse[1])}`}</p>
            </div>
          )}
          <div className='p-4 rounded-b-xl absolute top-[64%] w-full'>
            <div className='w-full bg-white px-5 py-3 flex flex-col rounded-xl gap-2 text-sm h-[115px]'>
              <div className='flex justify-between items-center'>
                <h2
                  className='text-lg text-[#006938] font-bold whitespace-nowrap'
                  data-tooltip-id='my-tooltip'
                  data-tooltip-content={item.listPrice}
                >
                  {formatPrice(item.listPrice)}
                </h2>
               <p className='text-xs text-gray-600 font-semibold'>
  {item.class.replace(/([a-z])([A-Z])/g, "$1 $2")}
</p>
              </div>
              <div className='flex gap-1 items-center'>
                <GrLocation size={22} className='shrink-0' />
                <h2
                  className='text-sm overflow-hidden text-ellipsis text-start font-normal whitespace-nowrap'
                  data-tooltip-id='my-tooltip'
                  data-tooltip-content={`${item.address.streetNumber} ${item.address.streetName}, ${item.address.city}, ${item.address.zip}`}
                >{`${item.address.streetNumber} ${item.address.streetName}, ${item.address.city}, ${item.address.zip}`}</h2>
              </div>
              <div className='flex items-center justify-between'>
              <div className='flex items-center gap-1 ml-1'>
  {item.details?.numBedrooms > 0 && (
    <div className='flex gap-1 items-center'>
      <IoBedOutline size={25} />
      <h4
        data-tooltip-id='my-tooltip'
        data-tooltip-content={item.details.numBedrooms}
      >
        {item.details.numBedrooms}
      </h4>
    </div>
  )}

  {item.details?.numBedrooms > 0 && item.details?.numBathrooms > 0 && (
    <span className='text-gray-300'>|</span>
  )}

  {item.details?.numBathrooms > 0 && (
    <div className='flex gap-1 items-center'>
      <LiaBathSolid size={25} />
      <h4
        data-tooltip-id='my-tooltip'
        data-tooltip-content={item.details.numBathrooms}
      >
        {item.details.numBathrooms}
      </h4>
    </div>
  )}
</div>

              </div>
            </div>
          </div>
        </div>
      </div>
    ))
  ) : (
    // Render "No Data Found" message if listData is empty
    <div className='text-center text-gray-600 col-span-full'>
      <p>No data found. Try adjusting your filters or search criteria.</p>
    </div>
  )}
</div>

            <div className="flex gap-5 justify-center mt-5 p-5">
                <button
                    className="bg-[#545350] p-4 rounded-full disabled:opacity-50"
                    onClick={handlePrevPage}
                    disabled={currentPage === 1}
                >
                    <FaArrowLeft color='#ffffff' />
                </button>
                {renderPageNumbers()}
                <button
                    className="bg-[#545350] p-4 rounded-full disabled:opacity-50"
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                >
                    <FaArrowRight color='#ffffff' />
                </button>
            </div>
            <Tooltip id="my-tooltip" />
        </>
    );
}

export default ListView;