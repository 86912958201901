import React from 'react'

function Accessibility4() {
  return (
    <div className='pt-24 container max-w-screen-lg mx-auto px-4 sm:px-6 md:px-8'>
        <div>
            <h2 className='text-center flex flex-col items-center gap-6 mb-6 mt-10 font-bold  text-[36px]'>Multi-Year Plan</h2>
        </div>
        <div className='single-content'>
            <p>This 2014-21 accessibility plan outlines the policies and actions that Royal LePage Real Estate Services Ltd. (the Company) will put in place to improve opportunities for people with disabilities.</p>
            <h2 className='text-start font-bold text-customBlue mb-3'>Statement of Commitment</h2>
            <p>The Company is committed to treating all people in a way that allows them to maintain their dignity and independence. We believe in integration and equal opportunity. We are committed to meeting the needs of people with disabilities in a timely manner, and will do so by preventing and removing barriers to accessibility and meeting accessibility requirements under the Accessibility for Ontarians with Disabilities Act.</p>
            <p>Under the AODA, the following accessibility standards set certain requirements that are applicable to the Company.</p>
            <ul className='ml-5'>
                <li className='text-start'>Customer Service</li>
                <li className='text-start'>Information and Communication</li>
                <li className='text-start'>Employment</li>
            </ul>
            <h2 className='text-start font-bold text-customBlue mb-3'>Customer Service Accessibility</h2>
            <p className='font-bold'>Commitment:</p>
            <p>The Company is committed to make our customer service accessible and training our staff on how to provide accessible service</p>
            <p className='font-bold'>The following has taken place since 2012</p>
            

            <ul>
            <li className='text-start'>Developed policies, procedure and practices for serving persons with disabilities</li>
            <li className='text-start'>Ensure our policies and procedures and practices are consistent with principles of independent, dignity, integration and equality</li>
            <li className='text-start'>Have a specific policy on allowing people to use their own assistive devices</li>
            <li className='text-start'>Communicate in a manner that takes into account the persons disability</li>
            <li className='text-start'>Have a policy that permits service animals</li>
            <li className='text-start'>Permit support people to accompany persons with disability</li>
            <li className='text-start'>Train all staff and contract employees to appropriately serve persons with disabilities</li>
            <li className='text-start'>Train people involved in developing the provider’s customer service policies, procedures and practices</li>
            <li className='text-start'>Provide feedback mechanism for persons with disability, response to and take action</li>
            <li className='text-start'>Document in writing all of our policies and procedures on how we provide accessible customer service.</li>
            <li className='text-start'>Notify customers that all the documents required are available upon request</li>
            <li className='text-start'>Provide documents in a format that takes into account the persons disability</li>
            <li className='text-start'>Online reporting to the Government on compliance</li>
          
            </ul>
            <h2 className='text-start font-bold text-customBlue mb-3'>Integrated Accessibility Standards Regulation</h2>
            <p className='font-bold'>1. Accessible Emergency Information</p>
            <div >
                <p className='font-bold'>Commitment:</p>
                <p>The Company is committed to providing the customers and clients with publicly available emergency information in an accessible way upon request. We will also provide employees with disabilities with individualized emergency response information when necessary.</p>
                <p className='font-bold'>Planned Action:</p>
                <p>The following measures were implemented. Emergency procedures information has been made available in an accessible format as soon as practicable, upon request.</p>
           
                <p className='font-bold'>2. Workplace Emergency Responses Information</p>
                <p className='font-bold'>Commitment:</p>
                <p>Where the Company is aware that an employee has a disability and that there is a need for accommodation, individualized workplace emergency response information will be provided to the employee as soon as practicable if such information is necessary given the nature of the employee’s disability</p>
                <p className='font-bold'>Planned Action:</p>
                <ul>
                    <ul>
                       <li className='text-start'>Where the Company becomes aware of the need to accommodate an employee’s disability, we will assess and in cases where needed will complete the individual emergency response information, if necessary. The Company will complete an individual workplace emergency response information to the employee as soon as practicable after it becomes aware of the need</li>
                       <li className='text-start'>If the employee requires workplace emergency assistance, with their consent, will provide the workplace emergency response information to the designated person who will be providing assistance to the employee.</li>
                       <li className='text-start'>The Company will review the individual workplace response information when:</li>

                       <ul>
                        <li className='text-start'>The employee moves to a different location in the organization</li>
                        <li className='text-start'>The employee’s overall accommodation needs or plans are reviewed</li>
                        <li className='text-start'>When the general emergency response is reviewed</li>
                       </ul>
                    </ul>
                </ul>
                <p className='font-bold'>Required compliance date: January 1, 2012</p>
                <p className='font-bold'>Status: Complete</p>
                <p className='font-bold'>3. Accessibility Policy and Multi Year plan</p>
                <div>
                    <p className='font-bold'>Commitment:</p>
                    <p>The Company is committed to implementing an Accessibility Policy; the policy is our commitment to meeting the accessible needs of persons with disabilities in a timely manner.</p>
                    <p className='font-bold'>Planned Action:</p>
                     <ul>
                        <ul>
                            <li className='text-start'>Create a multi-year accessibility plan outlining the company’s phased-in strategy for identifying and removing and preventing barriers to accessibility  </li>
                            <li className='text-start'>Plan to be posted on corporate website and will be provided in alternative format upon request</li>
                            <li className='text-start'>The plan will be reviewed a minimum of five years</li>
                        </ul>
                     </ul>
                </div>
                <p className='font-bold'>Required compliance date: January 1, 2014</p>
                <p className='font-bold'>Status: Complete</p>
                <div>
                    <p className='font-bold'>4. Training</p>
                    <p className='font-bold'>Commitment:</p>
                    <p>The Company will provide training to employees, volunteers, and persons participating in the development and approval of the Company policies are provided with appropriate training on the requirements if the IASR and on Ontario’s accessibility laws and on the Human Rights Code as it relates to people with disabilities. Training will be provided in a way that best suits the duties of employees, volunteers and other staff members.</p>
                    <p>The Company will take the following steps to ensure employees are provided with the training needed to meet Ontario’s accessible laws by&nbsp;<b>January 1, 2015</b></p>
                    <p className='font-bold'>Planned Action:</p>
                    <ul>
                        <ul>
                        <li className='text-start'>Develop and implement appropriate training materials</li>
                        <li className='text-start'>Create a plan for refresher training to ensure ongoing awareness</li>
                        <li className='text-start'>Keep and maintain a record of training provided, including dates that the training was provided and the number of individuals to whom it was provided</li>
                        <li className='text-start'>Ensure that training is provided on any changes to the policy on an ongoing basis</li>
                        </ul>
                    </ul>
                </div>
                <p className='font-bold'>Required compliance date: January 1, 2015</p>
             
           
           
           
           
            </div>
            
            <h2 className='text-start font-bold text-customBlue mb-3'>Information and Communications</h2>
            <p className='font-bold'>Commitment:</p>
            <p>The Company is committed to meeting the communication needs of people with disabilities. We will consult with people with disabilities to determine their information and communication needs.</p>
            <p className='font-bold'>i. Feedback</p>
            <p>The Company will take the following steps to make ensure existing feedback processes are accessible to people with disabilities upon request by&nbsp;</p>
            <p className='font-bold'>Planned Action:</p>
            <ul>
                <ul>
                    <li className='text-start'>Review and determine alternative formats that we can provide feedback processes if requested</li>
                    <li className='text-start'>Ensure that staff and management are aware of requirement and are informed</li>
                    <li className='text-start'>Ensure that feedback processes are accessible to persons with disability by providing or arranging for the provision of accessible formats and communication supports, upon request.</li>
                    <li className='text-start'>Provide or arrange for the provision of accessible formats and communication supports upon request for persons with disability in a timely manner that takes into account the person’s accessibility needs.</li>
                </ul>
            </ul>
            <p className='font-bold'>Required compliance date: January 1, 2015</p>
            <p className='font-bold'>Status: Complete</p>
               <div>
                 <p className='font-bold'>ii. Accessible Formats and Communication Supports</p>
                 <p>The Company will take the following steps to make sure all publicly available information is made accessible upon request by&nbsp;<b>January 1, 2016</b></p>
                 <p className='font-bold'>Planned Action:</p>
                 <ul>
                    <ul>
                        <li className='text-start'>Marketing or support material is available in alternate formats</li>
                        <li className='text-start'>Determine communication strategy on how information will be made available upon request, ( i.e. email, marketing material, website)</li>
                    
                    </ul>
                 </ul>
               </div>
               <p className='font-bold'>Required compliance date: January 1, 2016</p>
               <p className='font-bold'>Status: Complete</p>
               <div>
                 <p className='font-bold'>iii. Accessible Website and Web Content</p>
                 <p>In accordance with the IARS, the Company will ensure that the Company website and online content conforms with WCAG 2.0, Level A by<b>January 1, 2014</b>to Level AA for all content<b>January 1, 2021</b>&nbsp;</p>

                 <p className='font-bold'>Planned Action:</p>
                 <ul>
                    <ul>
                        <li className='text-start'>The Company launched a new website in 2015 and IT and Marketing have will ensure that the website and content conform with WCAG 2.0, Level A at that time</li>
                        <p className='font-bold'>Status: Complete</p>
                        <li>The Company to ensure its website content conforms to Level AA for all content by <b>January 1, 2021</b>.</li>
                        <p className='font-bold'>Status: Future date</p>
                    </ul>
                 </ul>
               </div>
            <h2 className='text-start font-bold text-customBlue mb-3'>Employment Standards</h2>
            
            <div>
                <p className='font-bold'>a. Recruitment</p>
                <p className='font-bold'>Commitment:</p>
                <p>The Company is committed to fair and accessible employment practices. </p>
                <p>We will take the following steps to notify the public and staff that, when requested, the Company will accommodate people with disabilities during the recruitment and assessment processes and when people are hired:</p>
                <p>Recruitment General</p>
                <p>The Company will notify employees and the public of the availability of accommodation for applicants with disability in the recruitment process:</p>
                <p className='font-bold'>Planned Action:</p>
                <ul>
                    <ul>
                        <li className='text-start'>Review and make necessary modification for existing recruitment policies and procedures and templates</li>
                        <li className='text-start'>Identify on job postings and notify employees that accommodations for job applicants with disabilities are available upon request during the recruitment process.</li>
                    </ul>
                </ul>
                <p className='font-bold'>a. Recruitment, Assessment and Selection</p>
                <p>The Company will notify job applicants, when they are selected to participate in an assessment or selection process that accommodations are available upon request in relation to the material or process to be used.</p>
                <p className='font-bold'>Planned Action:</p>
                <ul>
                    <ul>
                        <li  className='text-start'>Inclusion of availability of accommodation notices as part of the script in the scheduling of interview and or assessment</li>
                        <li  className='text-start'>If selected applicant requests an accommodation, consult with applicant and arrange for suitable accommodation that takes into account the applicants disability</li>
                    </ul>
                </ul>
                <p className='font-bold'>c. Notice to Successful Applicant</p>
                <p>When presenting an offer of employment, the Company will notify the successful applicant of its policies for accommodating employees with disability</p>
                <p className='font-bold'>Planned Action:</p>
                <ul>
                    <ul>
                        <li className='text-start'>When offering a job to a successful applicant, inform them of our policies on accommodating employees with disabilities.</li>
                        <li className='text-start'>Inclusion of notification of the Company policy on accommodating employees with disabilities in offer of employment</li>
                    </ul>
                </ul>
            </div>
            <p className='font-bold'>Required compliance date: January 1, 2016</p>
            <p className='font-bold'>Status: Complete</p>
            <h2 className='text-start font-bold text-customBlue mb-3'>Individual Accommodation Plan & Return to work Process</h2>
            <p className='font-bold'>Commitment</p>
            <p>The Company will take the following steps to develop and put in place a process for developing individual accommodation plans and return-to-work policies for employees that have been absent due to a disability:</p>
            <ul>
                <li className='text-start'>Review current process and develop a new process that includes the participation of the employee in the development of the individual accommodation plan. Captures everyone including those returning from a short or long term disability</li>
                <li className='text-start'>Determine efforts that will be made on how accommodation can be achieved i.e. internal and external resources</li>
                <li className='text-start'>Providing accommodation documentation in an accessible format that takes into account the employees disability.</li>
                <li className='text-start'>Accommodation plan will include individualized emergency response information</li>
            </ul>
            <p className='font-bold'>Required compliance date: January 1, 2016</p>
            <p className='font-bold'>Status: Complete</p>
            <h2 className='text-start font-bold text-customBlue mb-3'>Performance Management, Career Development and Redeployment</h2>
            <p className='font-bold'>Commitment</p>
            <p>We will take the following steps to ensure the accessibility needs of employees with disabilities needs are taken into account if the Company is using performance management, career development and redeployment processes:</p>
            <p className='font-bold'>Planned Action</p>
            <ul>
                <li className='text-start'>Review and assess existing policies, procedures, forms and templates to ensure compliance with IASR</li>
                <li className='text-start'>Take into consideration the needs of the employee with disability and their individual accommodation plan when assessing performance, managing career development and advancement and when if redeployment is required.</li>
                <li className='text-start'>Ensure Mangers are trained on what their role and responsibilities are as it pertains to dealing with employees with disability</li>
            </ul>
            <p className='font-bold'>Required compliance date: January 1, 2016</p>
            <p className='font-bold'>Status: Complete</p>
            <h2 className='text-start font-bold text-customBlue mb-3'>File Compliance Report: File Accessibility Compliance report every 3 years. Reports required no later than December 31 2014, 2017, 2020 and 2023.</h2>
            <p className='font-bold'>Completed: December 31 2014.</p>
            <p className='font-bold'    >Completed: Dec 31 2017</p>
            <p className='font-bold'>Next file date 2020</p>
            <h2 className='text-start font-bold text-customBlue mb-3'>For more information</h2>
            <p>For more information on this accessibility plan, please contact Aideen Kennedy at:</p>
            <div>
                <p>Phone: 416.510.5686</p>
                <p>Email:<span className='text-blue-600'>aideen.kennedy@brookfieldres.com</span> </p>
            </div>
        </div>
    </div>

  )
}

export default Accessibility4