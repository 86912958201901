import React, { useEffect, useState } from "react";
import {useParams,useLocation, useNavigate} from "react-router-dom";

import { GrLocation } from "react-icons/gr";
import { IoBedOutline } from "react-icons/io5";
import { LiaBathSolid } from "react-icons/lia";
import { BiArea } from "react-icons/bi";
import { getListings } from "../service";
import LoaderCard from "../components/loader";
import "../App.css"

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { CgCalendarDates } from "react-icons/cg";
import { CiFileOn } from "react-icons/ci";
import { MdKeyboardArrowRight } from "react-icons/md";
function Privacy() {
    const [similarListings, setSimilarListings] = useState([]);
    const [similarlistingLoading, setSimilarListingLoading] = useState(false);
    const slider = React.useRef(null);
    const [currentIndex, setCurrentIndex] = useState(0);
  
    
    
  
    const sliderSettings = {
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      dots: false,
      autoplay: false,
      initialSlide: 0,
      afterChange: (index) => setCurrentIndex(index),
    };
  
    useEffect(() => {
      getFeatureListings();
    }, []);
  
    const getFeatureListings = async () => {
      setSimilarListingLoading(true);
      const filterQuery =
        "&propertyType=Detached&minBeds=5&minBaths=5&minPrice=5000000&maxPrice=10000000";
      try {
        const result = await getListings(1, 3, filterQuery);
        setSimilarListings(result.data.listings);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setSimilarListingLoading(false);
      }
    };
    const formatDate = (dateString) => {
      console.log(dateString);
      const date = new Date(dateString);
      const options = { day: "2-digit", month: "short", year: "numeric" };
      const formattedDate = date.toLocaleDateString("en-GB", options);
      return formattedDate;
    };
  
    const formatPrice = (price) => {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
      }).format(price);
    };
  return (
    <div className="lg:pt-24 pt-16 bg-gray-50 pb-5 lg:px-24 px-5">
    <div className="  mt-32 flex gap-10 lg:flex-row flex-col lg:items-start" style={{ marginTop: "50px" }}>
      <div className="lg:w-[70%] w-full">
        <div className="privacy_heading mb-6">
          <h1
            className=" text-center flex flex-col items-center gap-6"
            style={{ fontFamily:' "Lato","sans-serif"', fontSize:"34px"}}
          >
            
					Johnston &amp; Daniel Privacy Policy
			
          </h1>
        </div>
    <div class="single-content text-start flex flex-col ">
                    <p className="text-start " style={{fontSize:"14px" ,lineHeight:"1.65em", color:"#464646"}}>At Johnston &amp; Daniel, a division of Royal LePage Real Estate Services Ltd., Brokerage (“J&amp;D”), we respect the privacy of our clients and we are committed to keeping your personal information accurate, confidential, secure and private. We have adopted the following Privacy Policy to ensure that J&amp;D continues to meet its commitment to your privacy.</p>
                    <p>The following sections, read as a whole, comprise the J&amp;D Privacy Policy:</p>


                    <ol className="pl-12">
                        <li style={{color:"#464646"}}>1.Introduction</li>
                        <li style={{color:"#464646"}}>2.The Ten Principles of Privacy</li>
                        <li style={{color:"#464646"}}>3.Application of the Ten Principles – How We Treat Your Personal Information
                          <ol className="pl-12" type="i">
                            <l style={{color:"#464646"}}i>i. The Kind of Information We Collect</l>
                            <li style={{color:"#464646"}}>ii. How We Use Your Information</li>
                            <li style={{color:"#464646"}}>iii. When We are Permitted to Disclose Your Information</li>
                            <li style={{color:"#464646"}}>iv. With Whom We May Share Your Information</li>
                            <li style={{color:"#464646"}}>v. How We Safeguard Your Information</li>
                            <li style={{color:"#464646"}}>vi. Web Site Security</li>
                            <li style={{color:"#464646"}}>vii. Accessing and Amending Your Information</li>
                            <li style={{color:"#464646"}}>viii. Opting Out</li>
                            <li v>xi. Web Site Usage Information and Cookies</li>
                          </ol>
                        </li>
                        <li style={{color:"#464646"}}>4.Updates</li>
                        <li style={{color:"#464646"}}>5.Questions, Concerns and Complaints</li>
                      </ol>



                      <p style={{color:"#464646"}}><b>1. Introduction</b></p>
                      <p style={{color:"#464646"}}>J&amp;D provides a variety of products and services to the public, including but not limited to: real estate brokerage services, real estate agency services, advising with respect to real estate purchase and sale, property leasing, property management, and other real estate advisory services.</p>
                      <p style={{color:"#464646"}}>In the course of providing these products and services, J&amp;D may collect, with consent, certain personal information about its clients, employees and J&amp;D brokers and agents.</p>
                      <p style={{color:"#464646"}}>“Personal Information” means information about an identifiable individual. This may include, without limitation, the individual‘s name, home address, age, income, credit history or other financial information, credit card information, personal preferences and other information about his, her or their family. Business contact information is also personal information, but consent is not required to collect, use or disclose this information for the purpose of communicating or facilitation communication with an individual in their business capacity.</p>
                      <p style={{color:"#464646"}}>The J&amp;D Privacy Policy is based on Canada’s Personal Information Protection and Electronic Documents Act (“PIPEDA”), which includes the Ten Privacy Principles outlined in the National Standard of Canada entitled Model Code for the Protection of Personal Information. For more information about this legislation, please visit the official web site of the Office of the Privacy Commissioner of Canada at <a href="https://www.priv.gc.ca" target="_blank">http://www.priv.gc.ca</a>.&nbsp;&nbsp;</p>
                      <p style={{color:"#464646"}}>This web site is governed by Canadian law.&nbsp; If you are located in a jurisdiction outside of Canada and have questions on the application of the laws of your country to our collection, use or disclosure of your personal information, then please contact your local privacy commissioner or data supervisory authority.</p>
                      <p style={{color:"#464646"}}>This Privacy Policy describes the ten principles that J&amp;D follows to protect your Personal Information when we collect, use or disclose it in the course of carrying on commercial activities in Canada. All of our employees and contractors who have access to Personal Information must adhere to the J&amp;D Privacy Policy and related procedures. To this end, J&amp;D has appointed a Chief Privacy Officer to ensure compliance by all J&amp;D employees and to address your privacy concerns.</p>
                      <p style={{color:"#464646"}}><b>2. The Ten Principles of Privacy</b></p>
                      <p style={{color:"#464646"}}>The following ten principles govern our actions as they relate to the collection, use and disclosure of Personal Information:</p>

                        <ul className="pl-5">
                        <li style={{textDecoration: "underline",color:"#464646"}}>Principle 1 - Accountability</li>
                        <ul style={{listStyle:"none"}}>
                        <li className="pl-5">J&amp;D is accountable for all Personal Information in its possession or control, including Personal Information transferred to third parties for processing.</li>
                        </ul>
                        <li style={{textDecoration: "underline",color:"#464646"}}>Principle 2 - Identifying Purposes</li>
                        <ul style={{listStyle:"none"}}>
                        <li className="pl-5">J&amp;D will inform you of the purposes for which it is collecting any Personal Information, before or at the time the information is collected.</li>
                        </ul>
                        <li style={{textDecoration: "underline",color:"#464646"}}>Principle 3 - Consent</li>
                        <ul style={{listStyle:"none"}}>
                        <li className="pl-5">As required under Canadian law, J&amp;D will obtain your consent before or when it collects, uses or discloses your Personal Information.Consent can be express or implied.  In certain cases consent may be given through an authorized representative. You may withdraw your consent at any time on reasonable prior notice (subject to any legal or contractual restrictions of which you will be informed). In certain exceptional circumstances J&amp;D may collect, use or disclose Personal Information without your knowledge or consent where such collection, use or disclosure is permitted or required by law.</li>
                        </ul>
                        <li style={{textDecoration: "underline",color:"#464646"}}>Principle 4 - Limiting Collection</li>
                        <ul style={{listStyle:"none"}}>
                        <li className="pl-5">The information collected by J&amp;D will be limited to those details necessary for the purposes we have identified to you or to perform the services you have requested. Information will be collected by fair and lawful means.</li>
                        </ul>
                        <li style={{textDecoration: "underline",color:"#464646"}}>Principle 5 - Limiting Use, Disclosure and Retention</li>
                        <ul style={{listStyle:"none"}}>
                        <li style={{color:"#464646"}} className="pl-5">Personal Information will only be used or disclosed for the purpose for which it was collected unless you have consented to use or disclosure for other purposes.<br/>
                           Personal Information will be handled in accordance with our document retention policies and will generally only be retained for the amount of time needed to fulfil the purpose for which it was collected, unless a longer period is required or permitted under applicable law.In certain exceptional circumstances, J&amp;D may have a legal duty or right to collect, use or disclose Personal Information without your knowledge or consent with respect to matters that concern the company’s or the public’s interest.</li>
                        </ul>
                        <li style={{textDecoration: "underline",color:"#464646"}}>Principle 6 - Accuracy</li>
                        <ul style={{listStyle:"none"}}>
                          <li style={{color:"#464646"}} className="pl-5">J&amp;D will keep Personal Information as accurate, complete and current as necessary to fulfil the identified purposes for which it was collected. You may have this information amended or supplemented where it is found to be inaccurate or incomplete.</li>
                          </ul>
                          <li style={{textDecoration: "underline",color:"#464646"}}>Principle 7 - Safeguarding Personal Information</li>
                          <ul style={{listStyle:"none"}}>
                            <li style={{color:"#464646"}} className="pl-5">Personal Information is safeguarded using measures appropriate to the sensitivity of the information.</li>
                            </ul>
                            <li style={{textDecoration: "underline",color:"#464646",color:"#464646"}}>Principle 8 - Openness</li>
                            <ul style={{listStyle:"none"}}>
                              <li style={{color:"#464646"}} className="pl-5">J&amp;D will make information available to its clients, employees and J&amp;D brokers and agents about the policies and procedures J&amp;D uses to manage personal information. You have access to this information through our Web site, or through alternate means if requested.</li>
                              </ul>
                              <li style={{textDecoration: "underline",color:"#464646"}}>Principle 9 - Access</li>
                              <ul style={{listStyle:"none"}}>
                                <li style={{color:"#464646"}} className="pl-5">Upon written request, you will be informed of the existence, use and disclosure of your Personal Information, and will be given access to it, except where prohibited by law. J&amp;D will respond to such requests as efficiently as possible. If J&amp;D is prohibited from providing such access we will explain the reasons for the lack of access, except where prohibited by law.</li>
                                </ul>
                                <li style={{textDecoration: "underline",color:"#464646"}}>Principle 10 – Challenging Compliance</li>
                                <ul style={{listStyle:"none"}}>
                                  <li style={{color:"#464646"}} className="pl-5">Individuals have the right to express their concerns with an organization’s compliance with these privacy principles.  J&amp;D has policies and procedures to receive, investigate and respond to complaints and questions regarding this Privacy Policy and our collection, use and disclosure of Personal Information. You may contact the J&amp;D Chief Privacy Officer to express any concerns with J&amp;D’s adherence with this Privacy Policy or to request access to your Personal Information. The contact information for our Chief Privacy Officer is provided at the end of this document.</li>
                                  </ul>
                        </ul>

                      <p style={{color:"#464646"}}><b>3. Application of the Ten Privacy Principles - How we treat your personal information</b></p>
                      <p style={{textDecoration: "underline",color:"#464646"}}>The Kind of Information We Collect</p>


                      <p style={{color:"#464646"}}>J&amp;D gathers and uses Personal Information in order to provide you with the products and services you have requested and to comply with applicable legislative requirements.  The following is a representative list of the types of information that we collect:</p>

                      <ul>
                      <li style={{color:"#464646"}}>Information required in accordance with FINTRAC rules to verify your identity or when necessary to qualify you as a seller, buyer, mortgagor, lessor or tenant:</li>
                      <ul>
                        <li style={{color:"#464646"}}>name, address and contact information;</li>
                        <li style={{color:"#464646"}}>date of verification;</li>
                        <l style={{color:"#464646"}}i>occupation/principal business;</l>
                        <li style={{color:"#464646"}}>date of birth;</li>
                        <li style={{color:"#464646"}}>document used to verify identity, including source information, ID number, issuing jurisdiction, and expiry date;</li>
                        <li style={{color:"#464646"}}>credit file information including credit bureau name, date we consulted the applicable credit bureau and your credit file reference number;</li>
                        <li style={{color:"#464646"}}>financial account number information and the documentation from two reliable sources to verify your identity;</li>
                        </ul>
                        <li style={{color:"#464646"}}>when collecting an e-signature, we save the geographic location of the signee;</li>
                        <li style={{color:"#464646"}}>identity verification information as above to verify the directors and officers of corporations;</li>
                        <li style={{color:"#464646"}}>your Social Insurance Number if we are obligated to collect it pursuant to the Income Tax Act, the Proceeds of Crime (Money Laundering) and Terrorist Financing Act or other applicable legislation;</li>
                        <li style={{color:"#464646"}}>information about real property that you own as required to effectively market, sell or rent properties on your behalf;</li>
                        <li style={{color:"#464646"}}>information such as resume and prior employment and reference information to evaluate real estate agent applicants and potential employees; and</li>
                        <li style={{color:"#464646"}}>information required to otherwise provide professional and competent real estate services to clients and to ensure that you are satisfied with the products and services we have provided.</li>
                      </ul>



                      <p style={{color:"#464646"}}>We do not collect personal information about you when you visit this web site (except for IP address, described below under “Web Site Usage Information and Cookies”) unless you provide it. All information that you do provide us with is securely maintained and kept strictly confidential. This Privacy Policy does not apply to aggregated data from which it is not possible to determine the identity of a specific individual. J&amp;D reserves the right to use anonymous, aggregated data in any way that it determines appropriate.</p>

                      <p style={{textDecoration: "underline",color:"#464646"}}>How We Use Your Information</p>

                      <p style={{color:"#464646"}}>At J&amp;D we use Personal Information for the following purposes:</p>

                      <ul>
                      <li style={{color:"#464646"}}>to verify your identity;</li>
                      <li style={{color:"#464646"}}>to market your real property for sale or lease;</li>
                      <li style={{color:"#464646"}}>to assist you in finding real property for sale or lease;</li>
                      <li style={{color:"#464646"}}>to communicate with you in order to provide you with our products and services, which includes providing you with updates about our services and offering you additional products or services that you may be interested in;</li>
                      <li style={{color:"#464646"}}>for inclusion in documentation relating to your particular real estate transaction;</li>
                      <li style={{color:"#464646"}}>to monitor our level of service and report back to you to ensure your satisfaction with the provision of our services; and</li>
                      <li style={{color:"#464646"}}>to consider your application for employment or engagement as a J&amp;D agent.</li>
                      </ul>

                      <p style={{color:"#464646"}}>We will only use Personal Information for the purpose that we have disclosed to you and for which you have provided your express or implied consent, unless use for another purpose without your consent is permitted or required under applicable law. If we want to use your information for a different purpose, we will notify you and ask for your consent first.</p>
                      <p style={{textDecoration: "underline",color:"#464646"}}>When We are Permitted to Disclose Your Information</p>
                      <p style={{color:"#464646"}}>J&amp;D is obliged to keep your Personal Information confidential except under the following circumstances:</p>

                      <ul>
                      <li style={{color:"#464646"}}>When Authorized by You Many of the services offered by J&amp;D require us to obtain Personal Information about you in order to perform the services you have engaged us to provide. For example, in the course of the real estate listing process, we will share your property and ownership information with our local real estate board and the Canadian Real Estate Association for the purposes of listing the property on the MLS® system and we will share your property information with those persons and companies involved in the real estate transaction such as other real estate brokerages and agents, property appraisers, home inspectors, financial institutions, and lawyers.We will always obtain consent from you first, and we will never use the information for purposes other than those we have told you about unless use for another purpose without your consent is permitted or required under applicable law.You may withdraw your consent at any time on reasonable prior notice, subject to any legal implications (which we will inform you about). In some cases, if you do not consent to our use or disclosure of certain Personal Information, we may be unable to continue to provide all or part of the services you have requested.</li>
                      <li style={{color:"#464646"}}>When Required by Law
<p style={{color:"#464646"}}>The type of information we are legally required to disclose usually relates to government tax and financial transaction reporting requirements. However in some cases, such as under a court order, we may be required to disclose certain information to persons specified in the court order. We will only provide the specific information requested and only upon being satisfied that the authorities have legitimate grounds to request the information.</p></li>
<li style={{color:"#464646"}}>When Permitted by LawCanada’s privacy legislation has provided for certain situations where J&amp;D is legally permitted to disclose Personal Information without your consent. Examples include situations involving the collection of debt in arrears, medical emergencies, or suspicion of illegal activities.</li>
                      </ul>


                      <p style={{textDecoration: "underline",color:"#464646"}}>With Whom We May Share Your Information</p> 

                       <ul>
                      <li style={{color:"#464646"}}>Real Estate Brokers, Agents and Employees. In the course of daily operations, we may disclose Personal Information to real estate brokers and agents, both at our brokerage and other firms.  Our employees will also have access to Personal Information.  Access to Personal Information is limited to those brokers, agents and employees with a legitimate reason to use it. As a condition of their engagement, all J&amp;D brokers, agents and employees are required to follow all applicable laws and regulations, including this Privacy Policy. Unauthorized use or disclosure of confidential client information by any J&amp;D broker, agent or employee is prohibited and may result in disciplinary measures.</li>
                      <li style={{color:"#464646"}}>Real Estate Boards As described above, in order to list a property on the MLS® system we will disclose to our local real estate board and the Canadian Real Estate Association the property and ownership information for the listed property. This information is made available through the MLS® system to other Realtors®, both in Canada and internationally.</li>
                      <li style={{color:"#464646"}}>J&amp;D Third Party Suppliers and Service Providers.<br/> As you may know, we may engage and coordinate third party suppliers to provide you with certain services offered through J&amp;D. Such suppliers are only given the information that is needed for them to provide the specific services that we have contracted them to provide and they are restricted from using this information for any other purpose. Suppliers and service providers are obliged to protect the confidentiality of your Personal Information and they are required to treat your Personal Information in accordance with this Privacy Policy <br/>.In some cases our service providers may be located outside of Canada in countries where the law provides for a different level of protection for Personal Information than that which is available under Canadian law.  If you would like to obtain more information about our policies and procedures regarding the use of service providers located outside of Canada, please contact our Chief Privacy Officer at the address listed in Section 5 below.</li>
                      <li style={{color:"#464646"}}>Sale of Business We may disclose your personal information to a third party and their professional advisors in connection with a sale, merger or other disposition (whether of assets, stock or otherwise) of our business. In this event we will require that the information be maintained as confidential and only used for the purposes of evaluating and completing the transaction.</li>
                      </ul>

                      <p style={{textDecoration:"underline",color:"#464646"}}>How We Safeguard Information</p>

                      <p style={{color:"#464646"}}>J&amp;D has organizational, technical and physical controls in place that are designed to maintain the security of its information and information systems. Files containing Personal Information are stored according to the sensitivity of the information contained therein. Appropriate controls (such as restricted access) are placed on our computer systems and data processing procedures. Physical access to areas where Personal Information is gathered, processed or stored is limited to authorized employees and contractors.</p>
                      <p style={{color:"#464646"}}>When you telephone a J&amp;D agent or employee to speak about your file, you may be asked for some Personal Information to identify you. This type of safeguard is designed to ensure that only you, or someone authorized by you, has access to your file.</p>
                      <p style={{color:"#464646"}}>As a further means of safeguarding your Personal Information, we follow commercially reasonable document retention practices and securely destroy your Personal Information once the purposes for which we collected it have been fulfilled, unless a longer period is permitted or required by law.</p>

                      <p style={{textDecoration:"underline",color:"#464646"}}>Third Party Web Sites</p>

                      <p style={{color:"#464646"}}>In order to serve you better, J&amp;D offers clients access to certain information about third party products and services through links on this web site to other third party web sites, including social media web sites. J&amp;D is in no way responsible and cannot guarantee the content or privacy of other web sites linked to <a className="text-Green-800" href="https://www.johnstonanddaniel.com" target="_blank">www.johnstonanddaniel.com</a>.</p>

                      <p style={{textDecoration:"underline",color:"#464646"}}>Accessing and Amending Your Information</p>

                      <p style={{color:"#464646"}}>You have the right to access the Personal Information we keep about you and have the right to verify or amend the information if it is shown to be inaccurate or incomplete. If you would like to view the Personal Information held in your file, please make a written request to the Chief Privacy Officer at the address listed below, under “Questions, Concerns and Complaints”. We will respond to your request as efficiently as possible.  Please note that we will request Personal Information from you to verify your identity before providing you with access.  Please also note that your right of access is not absolute; there are circumstances under which we may refuse access as provided under applicable law.</p>

                      <p style={{textDecoration:"underline",color:"#464646"}}>Opting Out</p>


                      <p style={{color:"#464646"}}>Should you wish to withdraw you consent to our collection, use or disclosure of your Personal Information, please contact the Chief Privacy Officer at the address, phone number or email address provided at the end of this document.  While we can accommodate your request in respect of the promotional materials you receive from us, if you wish to withdraw your consent for the other business purposes for which we collect, use and disclose your Personal Information, we may not be able to provide our services to you that are based on the use and disclosure of this information.</p>

                      <p style={{textDecoration:"underline",color:"#464646"}}>Web Site Usage Information and Cookies</p>

                      <p style={{color:"#464646"}}>J&amp;D may collect information about your usage of this web site. For example, our web servers may record certain information automatically when you visit the web site. Some of this information collected cannot be traced back to a specific person, while other information is anonymous.<br/> This web site information is collected using “cookies” (discussed below) and might include the pages you visited, your IP (Internet Protocol) address and other site usage statistics. This information is used for research and analytical purposes only (like evaluating how many visitors our web sites receive or which pages they visit most often). This aggregate data may be disclosed to third parties, but never with personally identifying information.</p>
                      <p style={{color:"#464646"}}>Cookies are small text files that contain a unique identification number that allows our computers to identify your web browser (but not you) each time you visit our web sites. The information helps J&amp;D improve the functionality of the site and enhance the navigation and security of your session. Most major web sites use this technology and most browsers are set up to accept them.</p>
                      <p style={{color:"#464646"}}>You may configure your browser to notify you when you receive a cookie, and you may refuse to accept them entirely. However, if you refuse to accept cookies, you may limit the functionality that we can provide to you when you visit our site.</p>

                      <p style={{color:"#464646"}}><b>4. Updates</b></p>
                      <p style={{color:"#464646"}}>We may update this Privacy Policy from time to time, so please check back often to review the latest version.  Following each update we will assume that you have familiarized yourself with the current version of our Privacy Policy and we will assume that we may collect, use and disclose your Personal Information in accordance with the updated Privacy Policy unless you advise us otherwise in writing.</p>
                      <p style={{color:"#464646"}}>This Privacy Policy was last updated on May 22, 2020.</p>
                      <p style={{color:"#464646"}}><b>5. Questions, Concerns and Complaints</b></p>

                      <p style={{color:"#464646"}}>If you have any questions, concerns or complaints about your Personal Information, or about the J&amp;D Privacy Policy, please contact our Chief Privacy Officer using the contact information provide below:</p>

                      <p style={{color:"#464646"}}>Chief Privacy Officer<br/> Johnston &amp; Daniel, a division of Royal LePage Real Estate Services Ltd., Brokerage <br/> 39 Wynford Drive, Suite 200 <br/>Toronto, Ontario M3C 3K5 <br/> T 416.306.4336<br/> <a className="text-Green-800" href="mailto:privacyofficer@johnstonanddaniel.com">privacyofficer@johnstonanddaniel.com</a></p>








                      
    </div>
      </div>
      <div className="lg:w-[30%] w-full pt-2 px-4 shadow-lg h-auto">
        <div className="mx-3">
          <h1
            className="text-3xl font-medium text-start mt-4"
            style={{ fontFamily: "Frank Ruhl Libre" }}
          >
            Latest Listings
          </h1>
        </div>
        <div className="p-1 px-3">
          <div className="flex justify-between items-center p-2">
           
          </div>
          {similarlistingLoading ? (
            Array.from({ length: 1 }).map((_, index) => (
              <LoaderCard key={index} />
            ))
          ) : (
            <div>
              {similarListings === 0 ? (
                <div>...Oops no similar data found </div>
              ) : (
                <>
                  {similarListings.map((item, index) => (
                    <div key={index}>
                      <div className="h-[350px] cursor-pointer m-2 relative bg-cover bg-center rounded-2xl overflow-hidden">
                        <img
                          src={item.images[0]}
                          alt="home"
                          className="rounded-2xl w-full h-full object-cover transform hover:scale-105"
                          style={{
                            transition:
                              "all 1s cubic-bezier(.3,1,.35,1) 0s, transform .5s ease",
                          }}
                          onClick={() =>
                            window.open(`
                              /property/${item.mlsNumber},
                              "_blank"
                            `)
                          }
                        />
                        <div className="p-4 rounded-b-xl absolute top-[55%] w-full">
                          <div className="w-full bg-white px-5 py-3 flex flex-col rounded-xl gap-2 text-sm">
                            <div className="flex justify-start items-center">
                              <h2 className="text-lg text-[#006938] font-bold whitespace-nowrap">
                                {formatPrice(item.listPrice)}
                              </h2>
                            </div>
                            <div className="flex gap-1 items-center">
                              <GrLocation size={20} className="shrink-0" />
                              <h2
                                className="text-base max-2xl:text-sm  overflow-hidden text-start text-ellipsis font-normal whitespace-nowrap"
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content={`${item.address.streetNumber} ${item.address.streetName}, ${item.address.city}, ${item.address.zip}`}
                              >{`${item.address.streetNumber} ${item.address.streetName}, ${item.address.city}, ${item.address.zip}`}</h2>
                            </div>
                            <div className="flex items-center justify-between">
                              <div className="flex items-center gap-2">
                                <div className="flex gap-1 items-center">
                                  <IoBedOutline size={20} />
                                  <h4
                                    data-tooltip-id="my-tooltip"
                                    data-tooltip-content={
                                      item.details.numBedrooms
                                        ? item.details.numBedrooms
                                        : 0
                                    }
                                  >
                                    {item.details.numBedrooms
                                      ? item.details.numBedrooms
                                      : 0}
                                  </h4>
                                </div>
                                <span className="text-gray-300">|</span>
                                <div className="flex gap-1 items-center">
                                  <LiaBathSolid size={20} />
                                  <h4
                                    data-tooltip-id="my-tooltip"
                                    data-tooltip-content={
                                      item.details.numBathrooms
                                        ? item.details.numBathrooms
                                        : 0
                                    }
                                  >
                                    {item.details.numBathrooms
                                      ? item.details.numBathrooms
                                      : 0}
                                  </h4>
                                </div>
                                <span className="text-gray-300">|</span>
                                <div className="flex gap-1 items-center">
                                  <BiArea size={20} />
                                  <h4
                                    className="truncate"
                                    data-tooltip-id="my-tooltip"
                                    data-tooltip-content={
                                      item.lot.width
                                        ? `${item.lot.depth} x ${item.lot.width}`
                                        : "0 x 0"
                                    }
                                  >
                                    {item.lot.width
                                      ? `${item.lot.depth} x ${item.lot.width}`
                                      : "0 x 0"}
                                  </h4>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  </div>

  )
}

export default Privacy