import React, { useContext, useState, useEffect } from 'react';
import { FaAngleDown } from 'react-icons/fa';
import { IoSearchOutline } from 'react-icons/io5';
import { FilterContext } from '../components/FilterContext';
import { MdClose } from "react-icons/md";
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { LiaBathSolid } from "react-icons/lia";
import { IoBedOutline } from "react-icons/io5";
import ListView from '../components/listview';
import TabComponent from '../components/TabComponent';
import DialogModal from "../components/photosDialog";
import MapComponent from '../components/map';
import { useParams } from 'react-router-dom';






const PurchaseTypeOption = [
    'Buy',
    'Lease'
]
const locationOption = [
    'Toronto',
    'Mississauga',
    'Brampton',
    'Hamilton',
    'Markham',
    'Vaughan',
    'Oakville',
    'Burlington',
    'Richmond Hill',
    'Barrie',
    'Oshawa',
    'Ajax',
    'Pickering',
    'Whitby',
    'Milton',
    'Newmarket',
    'Muskoka',
    'Kawartha Lakes'
]
const propertiesOption = [
    { id: 1, name: 'All Property Types', key: 'All' },
    { id: 2, name: 'Detached', key: 'Detached' },
    { id: 3, name: 'Semi-Detached', key: 'Semi-Detached' },
    { id: 4, name: 'Condo Apt', key: 'Condo Apt' },
    { id: 5, name: 'Condo Townhouse', key: 'Condo townhouse' },
    { id: 6, name: 'Duplex', key: 'duplex' },
    { id: 7, name: 'Multiplex', key: 'multiplex' },
    { id: 8, name: 'Vacant Land', key: 'vacant land' },
    { id: 9, name: 'Farm', key: 'farm' }
];
const Buy2 = () => {

    const {
        propertyType,
        setPropertyType,
        location,
        setLocation,
        price,
        setPrice,
        minPrice,
        setMinPrice,
        maxPrice,
        setMaxPrice,
        bedrooms,
        setBedrooms,
        bathrooms,
        setBathrooms,
        mlsNumber,
        setMlsNumber,
        purchaseType,
        setPurchaseType,
        status,
        setStatus,
        currentView,
        setCurrentView,
        sortType, setSortType
    } = useContext(FilterContext);

    const [purchaseToggle, setPurchaseToggle] = useState(false);
    const [locationToggle, setLocationToggle] = useState(false);
    const [propertyTypeToggle, setPropertyTypeToggle] = useState(false);
    const [priceToggle, setPriceToggle] = useState(false);
    const [bathsToggle, setBathsToggle] = useState(false);
    const [showBedrooms, setShowBedrooms] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [property, setProperty] = useState('All Property Types');
    const [openDropdown, setOpenDropdown] = useState(null);
    const [filterData, setFilterData ] = useState(null)
    const [searchQuery, setSearchQuery] = useState('Toronto');
    console.log(searchQuery,"searchQuery");
    const {name} = useParams();
    console.log(name,"nameeeeeeeeeeee");


    React.useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    useEffect(() => {
        setPrice([0, purchaseType === 'Lease' ? 50000 : 3200000]);
        if(name){
            setSearchQuery(name)
            setLocation(name)
        }
    }, [purchaseType, setPrice]);

    const handlePurchaseToggle = () => {
        setOpenDropdown(prev => (prev === 'purchaseType' ? null : 'purchaseType'));
    }
    const handleLoactionToggle = () => {
        setOpenDropdown(prev => (prev === 'location' ? null : 'location'));
    }
    const handlePropertyTypeToggle = () => {
        setOpenDropdown(prev => (prev === 'propertyType' ? null : 'propertyType'));
    }
    const handlePriceToggle = () => {
        setOpenDropdown(prev => (prev === 'price' ? null : 'price'));
    }
    const handleRangeChange = (e) => {
       
        setMinPrice(parseInt(e.target.value))
       
      
    };
    const handleAfterChange = (value) => {
        setMinPrice(value[0]);
        setMaxPrice(value[1]);
    };
    const handleBathsToggle = () => {
        setOpenDropdown(prev => (prev === 'bath' ? null : 'bath'));
        
    };
    const handleBedsToggle = () => {
        setOpenDropdown(prev => (prev === 'bed' ? null : 'bed'));
        
    };
    const closeModal = () => {
        setIsModalOpen(false);
        document.body.classList.remove('overflow-hidden');
    };
    const closeToggle = () => {
            setOpenDropdown(null)
    }
    const selectPropertyType = (item) => {
        propertiesOption.map((property) => {
            if (property.id === item.id) {
                setProperty(property.name);
                setPropertyType(property.key);
            }
        });
    };
    const handleClickOutside = (event) => {
        if (!event.target.closest('.relative')) {
            setPurchaseToggle(false);
            setLocationToggle(false);
            setPropertyTypeToggle(false);
            setPriceToggle(false);
            setBathsToggle(false)
            setShowBedrooms(false)
            setOpenDropdown(null)
        }
    };

   

    const filteredLocations = locationOption.filter(location =>
        location.toLowerCase().includes(searchQuery.toLowerCase())
    );
    const handleFilters = (data) => {
        setFilterData(data)
    }

    const handleValue = (e) =>{
        setMaxPrice(parseInt(e.target.value))
    }
    

    return (
        <div className='lg:pt-28 pt-20'>
            <div className="flex items-start justify-between space-x-4 p-4 bg-white border-b border-gray-200 rounded-lg  max-sm:flex-col max-sm:items-start max-sm:gap-2 max-sm:w-full">
                <div className='flex gap-2 max-sm:flex-col max-sm:w-[100%] max-sm:justify-start  md:flex-wrap lg:flex'>
                    <div className='relative'>
                        <div
                            className={`px-4 py-2 border border-gray-300 rounded-md flex items-center justify-between cursor-pointer w-24 max-sm:w-[100%]`}
                            onClick={() => handlePurchaseToggle()}>
                            {purchaseType} <span className="ml-2"><FaAngleDown /></span>
                        </div>
                        {openDropdown === 'purchaseType' && (
                            <div className='absolute z-30 top-12 left-0 bg-white shadow-md rounded-md w-full'>
                                {PurchaseTypeOption.map(item => (
                                    <div key={item}
                                        className={`px-4 py-2 flex items-center cursor-pointer ${purchaseType === item ? 'bg-Stone-500 text-white' : ''}`}
                                        onClick={() => {
                                            setPurchaseType(item);
                                            closeToggle();
                                        }}>
                                        {item}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>

                    <div className="relative flex items-center border border-gray-300 rounded-md px-4 py-2 w-72 max-sm:w-[100%]">
                        <span className="mr-2 text-gray-500"><IoSearchOutline size={22} /></span>
                        <input
                            type="text"
                            placeholder="location"
                            className="outline-none ml-2 flex-grow text-gray-700 max-sm:w-[100%]"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            onClick={() =>  handleLoactionToggle('location')}
                        />
                        <span className="ml-2 text-gray-500 cursor-pointer" onClick={() => setSearchQuery('')}> <MdClose /></span>
                        {openDropdown === 'location' && (
                            <div className='absolute z-30 max-h-72 overflow-auto top-12 left-0 bg-white shadow-md rounded-md w-full'>
                                {filteredLocations.map(location => (
                                    <div key={location}
                                        className={`px-4 py-2 hover:bg-Stone-500 hover:text-white flex items-center cursor-pointer`}
                                        onClick={() => {
                                            setLocation(location);
                                            setSearchQuery(location);
                                            closeToggle()
                                        }}>
                                        {location}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>

                    <div className='relative'>
                        <div className="px-4 py-2 border justify-between border-gray-300 rounded-md flex items-center cursor-pointer w-48 truncate max-sm:w-[100%]"
                            onClick={() => handlePropertyTypeToggle('propertyType')}>
                            {property} <span className="ml-2"><FaAngleDown /></span>
                        </div>
                        {openDropdown === 'propertyType' && (
                            <div className='absolute top-12 z-30 left-0 bg-white shadow-md rounded-md w-full max-h-72 overflow-auto'>
                                {propertiesOption.map(item => (
                                    <div key={item.id}
                                        className={`px-4 hover:bg-Stone-500 hover:text-white py-2 flex items-center cursor-pointer ${property === item.name ? 'bg-Stone-500 text-white' : ''}`}
                                        onClick={() => {
                                            selectPropertyType(item)
                                            closeToggle()
                                        }}>
                                        {item.name}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>

                    <div className='relative'>
                        <div className="px-4 py-2 border border-gray-300 rounded-md flex items-center  justify-between cursor-pointer w-48 max-sm:w-[100%]"
                            onClick={() => {
                            handlePriceToggle('price')
                            }}>
                            <p className='  truncate'>Price</p>
                            <span className="ml-2"><FaAngleDown /></span>
                        </div>
                        {openDropdown === 'price' && (
                            <>
                                <div className='absolute z-30 top-12 p-3 left-0 bg-white shadow-md rounded-md lg:w-72 w-full'>
                                    <div className='flex justify-between items-center'>
                                   <div className='text-start mx-2'>
                                    <label>Min</label>
                                    <input 
                                    placeholder='Min'
                                    onChange={handleRangeChange}
                                    className='w-[100px] border outline-none p-2 rounded'
                                    >

                                    </input>
                                   </div>
                                    <hr className='h-[2px] w-7 lg:mt-6 mt-0 max-sm:mt-0 bg-black'></hr>
                                    <div className='text-start  mx-2'>
                                    <label>Max</label>
                                    <input 
                                    onChange={handleValue}
                                    placeholder='Max'
                                   

                                    className='w-[100px] border outline-none p-2 rounded'
                                    >

                                    </input>
                                   </div>
                                    </div>
                                    {/* <Slider
                                        range
                                        min={0}
                                        max={purchaseType === 'Lease' ? 50000 : 3200000}
                                        value={price}
                                        onChange={handleRangeChange}
                                       
                                        className="w-full h-2 mb-6 rounded-lg appearance-none cursor-pointer"
                                    />
                                    <div className="flex justify-between mt-2">
                                        <span>${price[0].toLocaleString()}</span>
                                        <span>${price[1].toLocaleString()}</span>
                                    </div> */}
                                </div>
                            </>
                        )}
                    </div>

                    <div className='relative'>
                        <div className="px-4 py-2 justify-between border border-gray-300 rounded-md flex items-center cursor-pointer w-24 gap-2 max-sm:w-[100%]"
                            onClick={() => handleBathsToggle('bath')}
                        >
                            <div className='flex items-center gap-1'>
                                <LiaBathSolid />
                                <p className="text-sm">{bathrooms ? `${bathrooms}` : 'All'}</p>
                            </div>
                            <FaAngleDown />
                        </div>
                        {openDropdown === 'bath' && (
                            <div className="absolute z-30 top-12  left-0 p-3 bg-white shadow-md rounded-md w-full">
                                {['All', 1, 2, 3, 4, '5+'].map((bath, index) => (
                                    <div
                                        key={index}
                                        className="flex items-center border-gray-200 py-1 gap-3 cursor-pointer hover:bg-gray-100"
                                        onClick={() => {(bath === 'All' ? setBathrooms(null) : setBathrooms(bath))
                                            closeToggle()
                                        }}

                                    >
                                        <LiaBathSolid />
                                        <span>{bath}</span>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>


                    <div className="relative">
                        <div
                            className="px-4 py-2 justify-between border border-gray-300 rounded-md flex items-center cursor-pointer gap-3"
                            onClick={() => {
                            handleBedsToggle()
                            }}
                        >
                            <div className='flex gap-1 items-center'>
                                <IoBedOutline />
                                <p className="text-sm">{bedrooms ? `${bedrooms} ` : 'All'} </p>
                            </div>

                            <FaAngleDown />
                        </div>
                        {openDropdown === 'bed' && (
                            <div className="absolute z-30 top-12 left-0 p-3 bg-white shadow-md rounded-md w-full">
                                {['All', 1, 2, 3, 4, '5+'].map((bed, index) => (
                                    <div
                                        key={index}
                                        className="flex items-center  py-1 gap-3 cursor-pointer hover:bg-gray-100"
                                        onClick={() => {
                                        (bed === 'All' ? setBedrooms(null) : setBedrooms(bed))
                                            
                                            closeToggle()
                                        }}
                                    >
                                        <IoBedOutline />
                                        <span>{bed}</span>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>

                <div className="px-4 py-2 border border-gray-300 rounded-md flex items-center cursor-pointer max-sm:items-start md:items-start max-sm:w-full " style={{margin:'0px'}}
                onClick={() => {
                    setIsModalOpen(!isModalOpen);
                    if (!isModalOpen) {
                        document.body.classList.add('overflow-hidden');
                    } else {
                        document.body.classList.remove('overflow-hidden');
                    }
                    closeToggle();
                }}>
                    &#x2699; More Filters
                </div>
                <DialogModal isOpen={isModalOpen} onClose={closeModal} onSubmit={handleFilters} index={''} title={'filter'} />
            </div>
            <div className=''>
                <TabComponent />
            </div>

            {currentView === 'map' && (
                <div className='mt-5 h-[500px]'>
                    <MapComponent
                        propertyType={propertyType}
                        location={location}
                        priceRange={price}
                        minPrice={price[0]}
                        maxPrice={price[1]}
                        beds={bedrooms}
                        baths={bathrooms}
                    />
                </div>
            )}

            { currentView === 'list' && (
                <ListView
                propertyType={propertyType}
                location={location}
                priceRange={price}
                minPrice={minPrice}
                maxPrice={maxPrice}
                beds={bedrooms}
                baths={bathrooms}
                mlsNumber={mlsNumber}
                purchaseType={purchaseType}
                status={status}
                filterTrue = {filterData}
                sortType = {sortType}
            />
            )}

            {/* listings */}
            

        </div>

    );

}

export default Buy2;