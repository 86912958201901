
import React, { useEffect, useState } from "react";
import {useParams,useLocation, useNavigate} from "react-router-dom";

import { GrLocation } from "react-icons/gr";
import { IoBedOutline } from "react-icons/io5";
import { LiaBathSolid } from "react-icons/lia";
import { BiArea } from "react-icons/bi";
import { getListings } from "../service";
import LoaderCard from "../components/loader";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { CgCalendarDates } from "react-icons/cg";
import { CiFileOn } from "react-icons/ci";
import { MdKeyboardArrowRight } from "react-icons/md";

import img1 from "../assets/images/9.6-2-1-1-525x328.webp";
import img2 from "../assets/images/10-4-1-1-1.webp";
import img3 from "../assets/images/j-1-1-1-525x328.webp";
import img4 from "../assets/images/2.6-6-1-1-525x328.webp";
import img5 from "../assets/images/5.3-3-1-1-525x328.webp";
import img6 from "../assets/images/4-1-1-1-525x328.webp";
import img7 from "../assets/images/sell-1-1-1-525x328.webp";
import img8 from "../assets/images/x-4-1-1-525x328.webp";
// import img9 from "../assets/images/vecteezy_happy-asian-couple-client-tenant-buy-home-realtor-hand_25133377.jpg";



const Blog = () => {

  const blogArray = [
    {
      key: "Best areas for families with kids",
      img: img1,
      mainHead: "Best areas for families with kids",
      date: "March 4, 2016",
      content:
        "New York County as a whole covers a total area of 33.77 square miles (87.5 km2), of which 22.96 square miles (59.5  ",
      id: 1,
    },
    {
      key: "The top neighborhoods",
      img: img2,
      mainHead: "The top neighborhoods",
      date: "March 4, 2016",
      content:
        "New York County as a whole covers a total area of 33.77 square miles (87.5 km2), of which 22.96 square miles (59.5   ",

      id: 2,
    },
    {
      key: "Buyer's Guide",
      img: img3,
      mainHead: "Buyer's Guide",
      date: "May 27, 2016",
      content:
        "New York County as a whole covers a total area of 33.77 square miles (87.5 km2), of which 22.96 square miles (59.5  ",
      id: 3,
    },
    {
      key: "Seller's Guide",
      img: img4,
      mainHead: "Seller's Guide",
      date: "March 4, 2016",
      content:
        "New York County as a whole covers a total area of 33.77 square miles (87.5 km2), of which 22.96 square miles (59.5   ",
      id: 4,
    },
    {
      key: "Reasons to sell before New Year",
      img: img5,
      mainHead: "Reasons to sell before New Year",
      date: "May 27, 2016",
      content:
        "New York County as a whole covers a total area of 33.77 square miles (87.5 km2), of which 22.96 square miles (59.5   ",
      id: 5,
    },
    {
      key: "What to look for in an agent",
      img: img8,
      mainHead: "What to look for in an agent",
      date: "May 27, 2016",
      content:
        "New York County as a whole covers a total area of 33.77 square miles (87.5 km2), of which 22.96 square miles (59.5   ",
      id: 6,
    },
    {
      key: "The best of Torront’s vibrant cities from coast to coast",
      img: img7,
      mainHead: "The best of Torront’s vibrant cities from coast to coast",
      date: "May 27, 2016",
      content:
        "New York County as a whole covers a total area of 33.77 square miles (87.5 km2), of which 22.96 square miles (59.5  ",
      id: 7,
    },
    {
      key: "The Top 10 Attraction in Monteral",
      img: img6,
      mainHead: "The Top 10 Attraction in Monteral",
      date: "May 27, 2016",
      content:
        "New York County as a whole covers a total area of 33.77 square miles (87.5 km2), of which 22.96 square miles (59.5   ",
      id: 8,
    },
  ];

  const location = useLocation()
  const navigate = useNavigate()
  const { key } = useParams();
  console.log("key",key)
 
  const [similarListings, setSimilarListings] = useState([]);
  const [similarlistingLoading, setSimilarListingLoading] = useState(false);
  const [blogValue, setBlogValue] = useState([])
  console.log(blogValue,"blogValue");
  
  const slider = React.useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  // const { selectedblog} = location.state || {}; 
  // console.log(selectedblog,"selectedblog");
  // const selectedblog = blogArray?.find((agent) => String(agent.id) === id);

  const sliderSettings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    autoplay: false,
    initialSlide: 0,
    afterChange: (index) => setCurrentIndex(index),
  };

  useEffect(() => {
    getFeatureListings();
    console.log(key,"sect skey")
    if(key){
      const selectedBlog = blogArray.find((blog) => blog.key === key);
      setBlogValue(selectedBlog)
      console.log('selectedBlog',selectedBlog)

    }
    
  }, []);

  const getFeatureListings = async () => {
    setSimilarListingLoading(true);
    const filterQuery =
      "&propertyType=Detached&minBeds=5&minBaths=5&minPrice=5000000&maxPrice=10000000";
    try {
      const result = await getListings(1, 3, filterQuery);
      setSimilarListings(result.data.listings);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setSimilarListingLoading(false);
    }
  };
  const formatDate = (dateString) => {
    console.log(dateString);
    const date = new Date(dateString);
    const options = { day: "2-digit", month: "short", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-GB", options);
    return formattedDate;
  };

  const formatPrice = (price) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
    }).format(price);
  };




  

  // const hanldeSub = (id) =>{
  //   console.log('id',id)
  //   const selectedblog = blogArray?.find((agent) => agent.id === id);
  //   console.log('select',selectedblog)
  //   if(selectedblog){
  //     console.log('test')
  //     navigate(`/neighborhoods/${id}`, { state: { selectedblog }});
  
  //   }
  //  }
  return (
    <div className="pt-24 pb-5  bg-gray-100 max-sm:pt-10" >


      <div className="m mx-10 flex gap-10 items-start container max-sm:flex-col md:flex-col lg:flex-row  " style={{marginTop:"40px"}}>
        <div className="w-[70%]  flex flex-col  max-sm:w-[100%] md:w-[100%] lg:w-[70%]">
          
          <div className=" flex flex-col gap-10 h-auto overflow-scroll h-svh profile ">
            {/* {selectedblog.map((item, index) => ( */}
              <div className="p-8 rounded-lg   bg-white shadow-md  max-sm:px-5">
                 <h1 className=" font-bold hover:text-gold-400 text-start " style={{ fontFamily: '"Bodoni Moda", "Sans-serif"', fontSize:"30px",
          color:"black",
          lineHeight: "1.5em",}} >
                    {blogValue?.mainHead}
                  </h1>
                <div className="h-[400px]">
                    <div className="flex gap-16 my-5 max-sm:flex-col max-sm:gap-3">
                     <div className="flex items-center gap-2">

                    <CgCalendarDates/>
                    <h3 style={{fontSize:"13px", color:"#464646"}} className="text-base">
                    Posted by <span className="">hosting_1ngqfcbk  on  </span>{blogValue?.date}
                 </h3>
                     </div>
                <span className="flex items-center gap-3" style={{fontSize:"14px"}}> <CiFileOn/><h3  style={{fontSize:"13px", color:" #464646"}}className="text-base ">Buying Properties, Location, Price, Real Estate </h3> </span>
                
                
                
                    </div>
                  <img
                    src={blogValue?.img}
                    alt="home"
                    className=" w-full h-full object-cover "
                    
                    
                  />
                </div>
                <div className="h-[80px]">

                </div>
                <div className="mt-2  text-start p-2">
                 
                 

                  <p style={{fontSize:"14px" ,color:" #464646"}}>New York County as a whole covers a total area of 33.77 square miles (87.5 km2), of which 22.96 square miles (59.5 km2) are land and 10.81 square miles (28.0 km2) are water.</p>
                
                  <blockquote class="wp-block-quote is-layout-flow wp-block-quote-is-layout-flow my-5 ">
                   <h6 className="border-l-2 border-gold-400" ><p className="" style={{margin:"20px 0px 20px 10px",fontSize:"14px" ,color:" #464646"}}>A modern redrawing of the 1807 version of the Commissioner’s Grid plan for Manhattan, a few years before it was adopted in 1811. Central Park is absent.</p></h6>

                   <p className="mb-6" style={{fontSize:"14px" ,color:" #464646"}}>Duis mattis laoreet neque, et ornare neque sollicitudin at. Proin sagittis dolor sed mi elementum pretium. Donec et justo ante. Vivamus egestas sodales est, eu rhoncus urna semper eu. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Integer tristique elit lobortis purus bibendum, quis dictum metus mattis.</p>
                   </blockquote>
                   <h3 class="wp-block-heading text-3xl font-roboto mb-5">What you must know about the Seattle market</h3>

                   <p className="my-5" style={{fontSize:"14px" ,color:" #464646"}}>Curabitur massa magna, tempor in blandit id, porta in ligula. Aliquam laoreet nisl massa, at interdum mauris sollicitudin et. Mauris risus lectus, tristique at nisl at, pharetra tristique enim.</p>
                   <p className="mb-5" style={{fontSize:"14px" ,color:" #464646"}}>Nullam this is a link nibh facilisis, at malesuada orci congue. Nullam tempus sollicitudin cursus. Nulla elit mauris, volutpat eu varius malesuada, pulvinar eu ligula. Ut et adipiscing erat. Curabitur adipiscing erat vel libero tempus congue. Nam pharetra interdum vestibulum. Aenean gravida mi non aliquet porttitor. Praesent dapibus, nisi a faucibus tincidunt, quam dolor condimentum metus, in convallis libero ligula ut eros.</p>

                   <ul class="list-disc ml-6">
                        <li style={{fontSize:"14px" ,color:" #464646"}}>Lorem ipsum dolor sit amet, consectetuer adipiscing elit.</li>
                        <li style={{fontSize:"14px" ,color:" #464646"}}>
                            Aliquam tincidunt mauris eu risus.
                            <ol className="list-decimal ml-8 mt-2">
                            <li style={{fontSize:"14px" ,color:" #464646"}}>Lorem ipsum dolor sit amet, consectetuer adipiscing elit.</li>
                            <li style={{fontSize:"14px" ,color:" #464646"}}>Aliquam tincidunt mauris eu risus.</li>
                            <li style={{fontSize:"14px" ,color:" #464646"}}>Vestibulum auctor dapibus neque.</li>
                            </ol>
                        </li >
                        <li style={{fontSize:"14px" ,color:" #464646"}}>Vestibulum auctor dapibus neque.</li>
                    </ul>
                    <p className="my-5" style={{fontSize:"14px" ,color:" #464646"}}>Ut non gravida arcu. Vivamus non congue leo. Aliquam dapibus laoreet purus, vitae iaculis eros egestas ac. Mauris massa est, lobortis a viverra eget, elementum sit amet ligula. Maecenas venenatis eros quis porta laoreet.</p>
                    <h3 class="wp-block-heading  text-3xl font-roboto mb-5">Top 10 Seattle apartments for sell now trending</h3>

                    <p className="my-5" style={{fontSize:"14px" ,color:" #464646"}}>One neighborhood of New York County is contiguous with The Bronx. Marble Hill at one time was part of Manhattan Island, but the Harlem River Ship Canal, dug in 1895 to improve navigation on the Harlem River, separated it from the remainder of Manhattan as an island between the Bronx and the remainder of Manhattan. Before World War I, the section of the original Harlem River channel separating Marble Hill from The Bronx was filled in, and Marble Hill became part of the mainland.</p>

                    <p style={{fontSize:"13px" ,color:" #464646"}} className="my-5">Vivamus varius vitae dolor ac hendrerit. Vestibulum nec dolor ac nunc blandit aliquam. Nam at metus non ligula egestas varius ac sed mauris. Fusce at mi metus. Nam elementum dui id nulla bibendum elementum.</p>
                    <ul class="list-disc ml-6">
                        <li style={{fontSize:"14px" ,color:" #464646"}}>Lorem ipsum dolor sit amet, consectetuer adipiscing elit.</li>
                        <li style={{fontSize:"14px" ,color:" #464646"}}>
                            Aliquam tincidunt mauris eu risus.
                            <ol class="list-decimal ml-8 mt-2">
                            <li style={{fontSize:"14px" ,color:" #464646"}}>Lorem ipsum dolor sit amet, consectetuer adipiscing elit.</li>
                            <li style={{fontSize:"14px" ,color:" #464646"}}>Aliquam tincidunt mauris eu risus.</li>
                            <li style={{fontSize:"14px" ,color:" #464646"}}>Vestibulum auctor dapibus neque.</li>
                            </ol>
                        </li >
                        <li style={{fontSize:"14px" ,color:" #464646"}}>Vestibulum auctor dapibus neque.</li>
                    </ul>
                    <p style={{fontSize:"14px" ,color:" #464646"}} className="my-5">Proin sagittis dolor sed mi elementum pretium. Donec et justo ante. Vivamus egestas sodales est, eu rhoncus urna semper eu. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Integer tristique elit lobortis purus bibendum, quis dictum metus mattis. Phasellus posuere felis sed eros porttitor mattis. Curabitur massa magna, tempor in blandit id, porta in ligula. Aliquam laoreet nisl massa, at interdum mauris sollicitudin et.</p>
                </div>
              </div>

              <div id="comments" className="p-6  rounded-md shadow-md bg-white ">
  <div id="respond" className="comment-respond">
    <h3 id="reply-title" className="text-lg font-bold mb-4 text-gray-800 text-start">
      Leave a Reply
      <small className="ml-2 text-sm">
        <a 
          rel="nofollow" 
          id="cancel-comment-reply-link" 
          href="/find-the-most-attractive-townhouses-for-sale-in-austin/#respond" 
          className="text-blue-500 hover:underline hidden"
        >
          Cancel Reply
        </a>
      </small>
    </h3>
    <form 
      action="https://upbeat-perlman.35-203-5-196.plesk.page/wp-comments-post.php" 
      method="post" 
      id="commentform" 
      className="space-y-4"
    >
      <p className="comment-notes text-sm text-gray-600 text-start">
        Your email address will not be published.
      </p>
      
      <p className="comment-form-comment">
        <textarea 
          id="comment" 
          name="comment" 
          className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:bg-gray-200" 
          rows="5" 
          aria-required="true" 
          placeholder="Comment"
        ></textarea>
      </p>
      
      <p className="comment-form-author">
        <input 
          id="author" 
          name="author" 
          type="text" 
          className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:" 
          placeholder="Name"
          required
        />
      </p>
      
      <p className="comment-form-email">
        <input 
          id="email" 
          name="email" 
          type="email" 
          className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:bg-gray-200" 
          placeholder="Email"
          required
        />
      </p>
      
      <p className="comment-form-url">
        <input 
          id="url" 
          name="url" 
          type="url" 
          className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:bg-gray-200" 
          placeholder="Website"
        />
      </p>
      
      <p className="comment-form-cookies-consent flex items-center space-x-2">
        <input 
          id="wp-comment-cookies-consent" 
          name="wp-comment-cookies-consent" 
          type="checkbox" 
          className="w-4 h-4 text-blue-500 border-gray-300 rounded focus:ring focus:bg-gray-200"
        /> 
        <label htmlFor="wp-comment-cookies-consent" className="text-sm text-gray-600">
          Save my name, email, and website in this browser for the next time I comment.
        </label>
      </p>
      
      <p className="form-submit text-start">
      <button className="bg-[#D3B28C] text-white p-2 rounded-md hover:bg-[#C19F6B]">
            Post Comment
          </button>
      </p>
    </form>
  </div>
</div>


<div className="grid grid-cols-2 gap-6 max-sm:grid-cols-1">
  {blogArray.slice(0, 3).map((item, index) => (
    <div key={index} className="p-2 rounded-lg bg-white">
      <div className="h-[280px] p-2">
        <img
          src={item.img}
          alt="home"
          className="rounded-2xl w-full h-full object-cover transform hover:scale-105"
          style={{
            transition:
              "all 1s cubic-bezier(.3,1,.35,1) 0s, transform .5s ease",
          }}
        />
      </div>
      <div className="mt-2 bg-white text-start p-2">
        <h1 className="text-1xl text-black font-medium hover:text-gold-400">
          {item.mainHead}
        </h1>
        <h3 style={{fontSize:"13px" ,color:" #464646"}} className="text-base mt-2">{item.date}</h3>
        <h3 style={{fontSize:"13px" ,color:" #464646"}} className="text-base mt-2">{item.content}</h3>

        <p className="text-base font-semibold mt-2 flex  cursor-pointer "  >  
          {/* onClick={()=>hanldeSub(id)} */}
         
          Continue Reading{" "}
          <span className="ml-2">
            {<MdKeyboardArrowRight size={24} />}
          </span>
        </p>
      </div>
    </div>
  ))}
</div>

        </div>
         
          </div>

         
        <div className="w-[30%] md:w-[100%] lg:w-[30%] max-sm:w-[100%] pt-2 px-4 shadow-lg h-auto overflow-scroll h-svh profile">
          <div className="mx-3">
            <h1 className="text-2xl font-bold text-start mt-4"  style={{fontFamily: '"Bodoni Moda", "Sans-serif"'}}>
              Latest Listings
            </h1>
          </div>
          <div className="p-1 px-3">
            <div className="flex justify-between items-center p-2">
            
            </div>
            {similarlistingLoading ? (
              Array.from({ length: 1 }).map((_, index) => (
                <LoaderCard key={index} />
              ))
            ) : (
              <div>
                {similarListings === 0 ? (
                  <div>...Oops no similar data found </div>
                ) : (
                  <>
                    {similarListings.map((item, index) => (
                      <div key={index}>
                        <div className="h-[350px] cursor-pointer m-2 relative bg-cover bg-center rounded-2xl overflow-hidden">
                          <img
                            src={item.images[0]}
                            alt="home"
                            className="rounded-2xl w-full h-full object-cover transform hover:scale-105"
                            style={{
                              transition:
                                "all 1s cubic-bezier(.3,1,.35,1) 0s, transform .5s ease",
                            }}
                            onClick={() =>
                              window.open(
                                `/property/${item.mlsNumber}`,
                                "_blank"
                              )
                            }
                          />
                          <div className="p-4 rounded-b-xl absolute top-[55%] w-full">
                            <div className="w-full bg-white px-5 py-3 flex flex-col rounded-xl gap-2 text-sm">
                              <div className="flex justify-start items-center">
                                <h2 className="text-lg text-[#006938] font-bold whitespace-nowrap">
                                  {formatPrice(item.listPrice)}
                                </h2>
                              </div>
                              <div className="flex gap-1 items-center">
                                <GrLocation size={20} className="shrink-0" />
                                <h2
                                  className="text-base max-2xl:text-sm  overflow-hidden text-start text-ellipsis font-normal whitespace-nowrap"
                                  data-tooltip-id="my-tooltip"
                                  data-tooltip-content={`${item.address.streetNumber} ${item.address.streetName}, ${item.address.city}, ${item.address.zip}`}
                                >{`${item.address.streetNumber} ${item.address.streetName}, ${item.address.city}, ${item.address.zip}`}</h2>
                              </div>
                              <div className="flex items-center justify-between">
                                <div className="flex items-center gap-2">
                                  <div className="flex gap-1 items-center">
                                    <IoBedOutline size={20} />
                                    <h4
                                      data-tooltip-id="my-tooltip"
                                      data-tooltip-content={
                                        item.details.numBedrooms
                                          ? item.details.numBedrooms
                                          : 0
                                      }
                                    >
                                      {item.details.numBedrooms
                                        ? item.details.numBedrooms
                                        : 0}
                                    </h4>
                                  </div>
                                  <span className="text-gray-300">|</span>
                                  <div className="flex gap-1 items-center">
                                    <LiaBathSolid size={20} />
                                    <h4
                                      data-tooltip-id="my-tooltip"
                                      data-tooltip-content={
                                        item.details.numBathrooms
                                          ? item.details.numBathrooms
                                          : 0
                                      }
                                    >
                                      {item.details.numBathrooms
                                        ? item.details.numBathrooms
                                        : 0}
                                    </h4>
                                  </div>
                                  {/* <span className="text-gray-300">|</span>
                                  <div className="flex gap-1 items-center">
                                    <BiArea size={20} />
                                    <h4
                                      className="truncate"
                                      data-tooltip-id="my-tooltip"
                                      data-tooltip-content={
                                        item.lot.width
                                          ? `${item.lot.depth} x ${item.lot.width}`
                                          : "0 x 0"
                                      }
                                    >
                                      {item.lot.width
                                        ? `${item.lot.depth} x ${item.lot.width}`
                                        : "0 x 0"}
                                    </h4>
                                  </div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blog;
