import React from 'react';
import '../../App.css';

function Accessibility2() {
  return (
    <div className="pt-24 container max-w-screen-lg mx-auto px-4 sm:px-6 md:px-8">
      <div>
        <h2 className="text-center flex flex-col items-center gap-6 mb-6 font-bold text-[28px] sm:text-[32px] md:text-[36px] mt-8">
          Accessibility For Persons With Disabilities Policy
        </h2>
      </div>
      <div className="single-content py-5">
        <p className="ml-0 sm:ml-3 text-sm md:text-base leading-relaxed">
          Royal LePage Real Estate Services Ltd. (the Company) strives to provide exceptional customer service to everyone in a dignified and respectful manner, and that meets all legislative requirements. The Accessibility for Ontarians with Disabilities Act (AODA) was passed in 2005. Its goal is to make Ontario accessible for people with disabilities by 2025. The Accessibility Standard for Customer Service applies to all businesses and organizations in Ontario with one or more employees.
        </p>

        <p className="ml-0 sm:ml-3 text-sm md:text-base leading-relaxed">
          The following is our policy for providing exceptional service to everyone, and applies to all Canadian the Company employees and business lines.
        </p>

        <p className="font-bold text-sm md:text-base">1. Our Mission</p>
        <p className="ml-0 sm:ml-3 text-sm md:text-base leading-relaxed">
          The Company is committed to providing quality real estate services to, and through, our leading franchise and corporate brokerage brands throughout Canada. As a provider of these services, we are committed to providing accessible customer service to people with disabilities.
        </p>

        <p className="font-bold text-sm md:text-base">2. Our Commitment</p>
        <p className="ml-0 sm:ml-3 text-sm md:text-base leading-relaxed">
          In fulfilling our mission, the Company is committed to providing its goods and services in a way that respects the dignity and independence of people with disabilities. We are also committed to providing people with disabilities the same opportunity to access our goods and services and allowing them to benefit from the same services, in the same place and in a similar way as other customers.
        </p>

        <p className="font-bold text-sm md:text-base">3. Providing Goods and Services to People with Disabilities</p>
        <p className="ml-0 sm:ml-3 text-sm md:text-base leading-relaxed">
          The Company is committed to excellence in serving all customers, including people with disabilities, and we will carry out our functions and responsibilities in the following areas:
        </p>

        <div className="pl-4 md:pl-6">
          <p className="font-bold text-sm md:text-base">1. Communication</p>
          <p className="pl-4 text-sm md:text-base leading-relaxed">
            The Company is committed to communicating with people with disabilities in ways that take into account their disability. We train staff to communicate with customers on how to interact and communicate with people with various types of disabilities.
          </p>

          <p className="font-bold text-sm md:text-base">2. Telephone Services</p>
          <p className="pl-4 text-sm md:text-base leading-relaxed">
            The Company is committed to providing fully accessible telephone services to our customers. We train staff to communicate with customers over the telephone in clear and plain language and to speak clearly and slowly. We will offer to communicate with customers by other means if telephone communication is not suitable to their communication needs or is not available.
          </p>

          <p className="font-bold text-sm md:text-base">3. Assistive Devices</p>
          <p className="pl-4 text-sm md:text-base leading-relaxed">
            The Company is committed to serving people with disabilities who use assistive devices to obtain, use, or benefit from our goods and services. We ensure that our staff is trained and familiar with various assistive devices that may be used by customers with disabilities while accessing our goods or services.
          </p>
        </div>

        <p className="font-bold text-sm md:text-base mt-6">4. Use of Service Animals and Support Persons</p>
        <p className="font-bold ml-0 sm:ml-3 mt-4 text-sm md:text-base">Service Animals</p>
        <p className="ml-0 sm:ml-3 text-sm md:text-base leading-relaxed">
          We are committed to welcoming people with disabilities who are accompanied by a service animal on the parts of our premises that are open to the public and other third parties. We will also ensure that all staff, volunteers, and others dealing with the public are properly trained in how to interact with people with disabilities who are accompanied by a service animal.
        </p>
        <p className="font-bold ml-0 sm:ml-3 mt-4 text-sm md:text-base">Support Persons</p>
        <p className="ml-0 sm:ml-3 text-sm md:text-base leading-relaxed">
          We are committed to welcoming people with disabilities who are accompanied by a support person. Any person with a disability who is accompanied by a support person will be allowed to enter the Company premises with his or her support person.
        </p>

        <p className="font-bold text-sm md:text-base mt-6">5. Notice of Temporary Disruption</p>
        <p className="ml-0 sm:ml-3 text-sm md:text-base leading-relaxed">
          The Company will provide customers with notice in the event of a planned or unexpected disruption in the facilities or services usually used by people with disabilities. This notice will include information about the reason for the disruption, the anticipated duration, and a description of alternative facilities or services, if available.
        </p>

        <p className="font-bold text-sm md:text-base mt-6">6. Training for Staff</p>
        <p className="ml-0 sm:ml-3 text-sm md:text-base leading-relaxed">
          The Company will ensure training for all employees, volunteers, and others who deal with the public. Training will include interacting with people with various types of disabilities and using assistive devices.
        </p>

        <p className="font-bold text-sm md:text-base mt-6">7. Feedback Process</p>
        <p className="ml-0 sm:ml-3 text-sm md:text-base leading-relaxed">
          The Company welcomes feedback, including feedback about the delivery of goods and services to persons with disabilities. Feedback can be submitted to Human Resources Manager at:
        </p>
        <div className="list-disc ml-5 sm:ml-8 text-sm md:text-base">
          <p>Email: <a href="mailto:accessibility@brookfieldres.com" className="text-blue-600">accessibility@brookfieldres.com</a></p>
          <p>Phone: 416.510.5686</p>
          <p>Address: 39 Wynford Drive, Toronto, ON M3C 3K5</p>
        </div>

        <p className="font-bold text-sm md:text-base mt-6">8. Modifications to this Policy</p>
        <p className="ml-0 sm:ml-3 text-sm md:text-base leading-relaxed">
          The Company is committed to developing customer service policies that respect and promote the dignity and independence of people with disabilities.
        </p>
      </div>
    </div>
  );
}

export default Accessibility2;
