import React, { useState, useEffect } from 'react';
import CustomDropdown from '../components/CustomDropdown';
import { CiSearch,CiMail } from "react-icons/ci";
import agent_1Img from '../assets/images/1572621278.jpg';
import agent_2Img from '../assets/images/1572621398.jpg';
import agent_3Img from '../assets/images/1672758583.jpg';
import agent_4Img from '../assets/images/1615409019.jpg';
import agent_5Img from '../assets/images/1629474252.jpg';
import agent_6Img from '../assets/images/1615575044.jpg';
import agent_7Img from '../assets/images/1572621802.jpg';
import agent_8Img from '../assets/images/1729868286_1158023.jpg';
import agent_9Img from '../assets/images/1615575521.jpg';
// import agent_4Img from '../assets/images/1615409019.jpg';
import OurTeamImg from "../assets/images/image.png"



import { IoCallOutline } from "react-icons/io5";
import { AiOutlineHome } from "react-icons/ai";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import { motion } from "framer-motion";
import {Link, useNavigate} from "react-router-dom"
import { div } from 'framer-motion/client';
import "../App.css"




const AgentListPage = () => {
  const navigate = useNavigate();
  const [agents, setAgents] = useState([]);
  console.log(agents);
  const [filteredAgents, setFilteredAgents] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [language, setLanguage] = useState('');
  const [position, setPosition] = useState('');
  const [designation, setDesignation] = useState('');
  const [office, setOffice] = useState('');
  const [totalPages, setTotalPages] = useState(0);
  const itemsPerPage = 2;
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const fetchedAgents = [
      { 
        id:1,
        name: 'Heather J. Lake',
        role: 'Sales Representative',
        location: 'Toronto, ON',
        email: 'Heather.com',
        phone: '416.587.5363',
        image: agent_1Img,
        language: 'English',
        position: 'Agent',
        designation: 'ABR - Accredited Buyers Representative',
        profileLink: '/profile/john-doe',
        office: 'J&D Toronto'
      },
      {
        id:2,
        name: 'Colin Lavery',
        role: 'Sales Representative',
        location: 'Vancouver, BC',
        email: 'Lavery@example.com',
        phone: '416.427.8424',
        image: agent_2Img,
        language: 'French',
        position: 'Expert',
        designation: 'CRA - Canadian Residential Appraiser',
        profileLink: '/profile/jane-smith',
        office: 'J&D Oakville'

      },
      { 
        id:3,
        name: 'Carter Levey',
        role: 'Sales Representative',
        location: 'Toronto, ON',
        email: 'Carter@example.com',
        phone: '416.606.6997',
        image: agent_3Img,
        language: 'English',
        position: 'Agent',
        designation: 'SRS - Seller Representative Specialistct',
        profileLink: '/profile/john-doe',
        office: 'J&D Muskoka'
      },
      {
        id:4,
        name: 'Anne Levenston',
        role: 'Sales Representative',
        location: 'Vancouver, BC',
        email: 'Anne@example.com',
        phone: '416.489.2121',
        image: agent_4Img,
        language: 'French',
        position: 'Expert',
        designation: 'MCNE - Master Certified Negotiation',
        profileLink: '/profile/jane-smith',
        office: 'J&D Kawarthas'
       
      },
      {
        id:5,
        name: 'Laura Logaridis',
        role: 'Sales Representative',
        location: 'Toronto, ON',
        email: 'Laura@example.com',
        phone: '416.489.2121',
        image: agent_5Img,
        language: 'English',
        position: 'Agent',
        designation: 'ABR - Accredited Buyers Representative',
        profileLink: '/profile/john-doe',
        office: 'J&D Toronto'
      },
      {
        id:6,
        name: 'Carol Lome',
        role: 'Broker',
        location: 'Vancouver, BC',
        email: 'Carol@example.com',
        phone: '098-765-4321',
        image: agent_6Img,
        language: 'French',
        position: 'Expert',
        designation: 'CRA - Canadian Residential Appraiser',
        profileLink: '/profile/jane-smith',
        office: 'J&D Oakville'

      },
      {
        id:7,
        name: 'Daniel Lynch',
        role: 'Sales Representative',
        location: 'Toronto, ON',
        email: 'john@example.com',
        phone: '123-456-7890',
        image: agent_7Img,
        language: 'English',
        position: 'Agent',
        designation: 'SRS - Seller Representative Specialistct',
        profileLink: '/profile/john-doe',
        office: 'J&D Muskoka'
      },
      {
        id:8,
        name: 'Kaitlin Lowy',
        role: 'Sales Representative',
        location: 'Vancouver, BC',
        email: 'Kaitlin@example.com',
        phone: '098-765-4321',
        image: agent_8Img,
        language: 'French',
        position: 'Expert',
        designation: 'MCNE - Master Certified Negotiation',
        profileLink: '/profile/jane-smith',
        office: 'J&D Kawarthas'
      },
      {
        id:9,
        name: 'Leslie Wallender Lyons',
        role: 'Sales Representative',
        location: 'Vancouver, BC',
        email: 'Leslie@example.com',
        phone: '098-765-4321',
        image: agent_9Img,
        language: 'French',
        position: 'Expert',
        designation: 'MCNE - Master Certified Negotiation',
        profileLink: '/profile/jane-smith',
        office: 'J&D Kawarthas'
      },
      // Add more agent objects here...
    ];
    setAgents(fetchedAgents);
    setFilteredAgents(fetchedAgents); 
  }, []);


  
  const   handleFilter = (query) => {
    setSearchTerm(query);
    const result = agents.filter(agent =>
      agent.name.toLowerCase().includes(query.toLowerCase()) ||
      agent.location.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredAgents(result);
  };
 
  const handleSearch = () => {
    // Handle the search functionality
    console.log(`Searching for term: ${searchTerm}, Language: ${language},`);
  };
  
  const handleLanguageChange = (selectedLanguage) => {
    setLanguage(selectedLanguage);
    if(selectedLanguage === 'All') {
      setFilteredAgents(agents);
      return;
    }
   let filtered = agents;
    if (selectedLanguage) {
      filtered = filtered.filter(agent => agent.language === selectedLanguage);
    }
    setFilteredAgents(filtered);
  };


  const handlePositionChange = (selectedPosition) => {
    setPosition(selectedPosition);
    if(selectedPosition === 'All') {
      setFilteredAgents(agents);
      return;
    }
    let filtered = agents;
    if (selectedPosition) {
      filtered = filtered.filter(agent => agent.position === selectedPosition);
    }
    setFilteredAgents(filtered);
  }
  const handleDesignationChange = (selectedDesignation) => {
    setDesignation(selectedDesignation);
    if(selectedDesignation === 'All') {
      setFilteredAgents(agents);
      return;
    }
    let filtered = agents;
    if (selectedDesignation) {
      filtered = filtered.filter(agent => agent.designation === selectedDesignation);
    }
    setFilteredAgents(filtered);
  };
  const handleOfficeChange = (selectedOffice) => {
    setOffice(selectedOffice);
    if(selectedOffice === 'All') {
      setFilteredAgents(agents);
      return;
    }
    let filtered = agents;
    if (selectedOffice) {
      filtered = filtered.filter(agent => agent.office === selectedOffice);
    }
    setFilteredAgents(filtered);
  }
  const handleNextPage = () => {
    if (currentPage < totalPages) {
        setCurrentPage(currentPage + 1);
    }
};

const handlePrevPage = () => {
    if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
    }
};

const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
};

const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPagesToShow = 5;
    const startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    const endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

    for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(
            <button
                key={i}
                className={`w-12 rounded-full text-sm ${i === currentPage ? 'bg-[#545350] text-white' : 'bg-gray-200'}`}
                onClick={() => handlePageClick(i)}
            >
                {i}
            </button>
        );
    }

    return pageNumbers;
};

const handleClearFilters = () => {
  // Reset all filter state variables to their default values
  setSearchTerm('');
  setLanguage('');
  setPosition('');
  setDesignation('');
  setOffice('');
  setFilteredAgents(agents); // Reset the filtered agents to the full list
};
  return (
  <>
    
                <div className=" bg-cover bg-center animate-fadeIn h-[650px] flex items-center "  style={{
                  backgroundImage:
                      `url(${OurTeamImg})`, 
              }}>
       <div className="absolute inset-0 bg-black bg-opacity-50 h-[650px]"></div>

                 <div className="relative text-center w-full">

                 
          <h3 style={{ color:" #FFFFFF",
            fontFamily: '"Bodoni Moda", "Sans-serif"',
            fontSize: "67px",
            fontWeight:" 400",
            lineHeight: "1.1em",
            textShadow: "0px 0px 10px rgba(0, 0, 0, 0.3)",}} className='mt-3'>Meet Our Team</h3>
          <h1 className='text-lg text-white'>professional real estate agents at your service</h1>
     
                 </div>

   
    </div>
   
    <div className=" p-4  px-32 max-2xl:px-20 font-roboto max-sm:px-5 md:px-5 lg:px-32">
         <h1 style={{fontSize: "45px",
            fontFamily: '"Bodoni Moda", "Sans-serif"',
            color:"black",
            lineHeight: "1.5em",}} class="entry-title mb-8  text-start">Agents List</h1>
      <div class="single-content text-start mb-8">
        <p className='text-start mb-3'>We like to think of ourselves as a small but perfectly formed lettings &amp; management agency. Working you get the exposure, knowledge and expertise you would expect from a large agent, but the service you will only receive from a smaller business built around 100% client and tenant focus.</p>
      <p>Whether you’re looking for property for sale in New York area or property for rent, WP Residence makes searching easy. Use our unique geolocation mapping feature to root-out your ideal villa, townhouse or apartment and contact the owners direct. We will help you find your dream house in just a few seconds.</p>
      </div>
  
      <div className="bg-white p-4 shadow-md rounded-lg flex items-center space-x-4 w-full  justify-around mx-auto max-sm:flex-col md:grid md:grid-cols-3 lg:flex ">
      {/* Language Dropdown */}
      <div className="p-2 max-sm:w-[100%]" style={{margin:"0px"}}>
      <CustomDropdown
        label="LANGUAGE"
        options={['All','English', 'French', 'Spanish', 'French', 'Italian', 'Portugese', 'German', 'Russian']}
        value={language}
        onChange={handleLanguageChange}
      
      />
    </div>

      {/* Region Dropdown */}
      <div className="p-2 max-sm:w-[100%] " style={{margin:"0px"}}>
      <CustomDropdown
        label="POSITION"
        options={['All','Broker', 'Agent', 'Expert', 'Consultant', 'Advisor', 'Associate', 'Administrator', 'Admin']}
        value={position}
        onChange={handlePositionChange}
        
      />
    </div>
    {/* Office Dropdown */}
    <div className="p-2 max-sm:w-[100%]  " style={{margin:"0px"}}>
      <CustomDropdown
        label="DESIGNATION"
        height={350}
        options={['All','ABR - Accredited Buyers Representative', 'CBR - Certified Buyer Representative', 'CNE - Certified Negotiation Expert', 'CRB - Certified Real Estate Brokerage', 'Manager', 'GREEN', 'Institute for Luxury Home Marketing', 'SRES - Seniors Real Estate Specialist',
          'SRS - Seller Representative Specialistct', 'MCNE - Master Certified Negotiation', 'CLHMS', 'CRA - Canadian Residential Appraiser', 'ASA - Accredited Senior Agent', 'Luxury Listing Specialist', 'ARIDO', 'IDC', 'LUXE Luxury Listing Specialist'
        ]}
        value={designation}
        onChange={handleDesignationChange}
      />
    </div>
    <div className="p-2 max-sm:w-[100%]" style={{margin:"0px"}}>
      <CustomDropdown
        label="OFFICE"
        options={['All','J&D Toronto', 'J&D Kawarthas', 'J&D Oakville', 'J&D Muskoka']}
        value={office}
        onChange={handleOfficeChange}
      />
    </div>

      {/* Search Box */}
      <div className="flex flex-col items-start max-sm:w-[100%]">
        <label className="text-gray-600 font-medium">SEARCH</label>
        <div className="flex items-center border-b border-gray-300 max-sm:w-[100%]">
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => handleFilter(e.target.value)}
            placeholder="search by agent name"
            className="w-full py-2 focus:outline-none max-sm:w-[100%]"
          />
          <span className="text-gray-500 ml-2">
            <CiSearch size={24}/>
          </span>
        </div>
      
      </div>
      <button
            className="px-4 py-2 mt-4 bg-Stone-600 text-white rounded hover:bg-Stone-700 focus:outline-none"
            onClick={handleClearFilters}
          >
           clear All
          </button>

    </div>

     
   

  {filteredAgents.length === 0 ?(
     <p className="text-center font-bold text-2xl text-gold-400 my-12">No agents found</p>
  ):(
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-8  mt-4 lg:grid-cols-3" >
  
    {filteredAgents.map((agent, index) => (
        <div
            key={index}
            onClick={() => navigate(`/ourTeam/${agent.name}`,{state:{agents}})}
            className="relative bg-black text-white rounded-lg overflow-hidden shadow-md hover:shadow-xl transition-shadow duration-300"
        >
            <div className="absolute inset-0 bg-black opacity-30"></div>

            {/* Replace the background image below with actual image URLs */}
            <div
                className="h-[27rem]  bg-cover bg-center"
                style={{
                    backgroundImage: `url(${agent.image})`,
                }}
            ></div>
            <div className="absolute bottom-4 left-4 text-start hover:text-gold-400">
                <h3 className="text-lg font-bold">{agent.name}</h3>
                <p className="text-sm">{agent.role}</p>
            </div>
        </div>
    ))}

</div>
  )}



  { totalPages > 1 && (
    <div className="flex gap-5 justify-center mt-8">
    <button
        className="bg-[#545350] p-4 rounded-full disabled:opacity-50"
        onClick={handlePrevPage}
        disabled={currentPage === 1}
    >
        <FaArrowLeft color='#ffffff' />
    </button>
    {renderPageNumbers()}
    <button
        className="bg-[#545350] p-4 rounded-full disabled:opacity-50"
        onClick={handleNextPage}
        disabled={currentPage === totalPages}
    >
        <FaArrowRight color='#ffffff' />
    </button>
</div>
  )}
      
    </div>
    </>
  );
};

export default AgentListPage;
