import logo from '../assets/images/J_D-Logo_15_-WHITE-BG-Transparent-bg.png';
import balcklogo from '../assets/images/J_D-Logo_15_-black-BG-Transparent-bg.png'
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useLocation,matchPath } from 'react-router-dom';
import AuthDialog from './Login-SignIn';
import { useAuth } from '../hooks/AuthContext';
import { FaRegUserCircle } from "react-icons/fa";
import { FiLogOut } from "react-icons/fi";
import { IoMdMenu } from "react-icons/io";

const HeaderComponent = () => {
 
  const { isAuthenticated, logout } = useAuth();
  console.log(isAuthenticated,"isAuthenticatedisAuthenticated");
  const [isScrolled, setIsScrolled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const currentPath = location.pathname;
  const navigate = useNavigate();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isLogin, setIsLogin] = useState(true);
  const [initialForm, setInitialForm] = useState('login'); 
  console.log('authentication',isAuthenticated)
  const userName = localStorage.getItem('user')
  console.log(userName,"username")
  const [showProfie, setShowProfile] = useState(false);

  const isActive = (path) => location.pathname === path;


  const openDialog = (formType) => {
    console.log(formType);
    setInitialForm(formType);
    setIsDialogOpen(true);
  };
  const closeDialog = () => setIsDialogOpen(false);
  const switchForm = () => setIsLogin(!isLogin);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };
    

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const sidebarVariants = {
      open: {
        x: 0, // Slide to 0px from left (fully visible)
        opacity: 1,
        transition: {
          type: 'spring',
          stiffness: 300,
          damping: 30,
        },
      },
      closed: {
        x: '100%', // Slide out of the screen
        opacity: 0,
        transition: {
          type: 'spring',
          stiffness: 300,
          damping: 40,
        }
      },
    };
    

    const propertyPaths = ['/property', '/branch', '/contact','/contact2','/buy','/buy2','/Accessibility','/privacy-policy','/home-valuation','/Accessibility-for-Ontarians-with-Disabilities-Act','/Accessibility-for-Persons-with-Disabilities-Policy',"/Integrated-Accessibility-Standards-Policy","/Multi-Year-Plan"];

    const isStaticPath = propertyPaths.includes(currentPath);
    const isDynamicPath = matchPath('/property/:id', currentPath) ||  matchPath('/ourTeam/:id',  currentPath)  || matchPath('/neighborhoods/:key',  currentPath) || matchPath('/ourstory/:name',  currentPath) || matchPath('/buy/:name',  currentPath);
    const isPropertyPage = isStaticPath || isDynamicPath;
    const isBuyPage = currentPath === '/buy'; 
    const isPropertyPage2 = matchPath('/property/:id', currentPath);
    const contactPage = currentPath === '/contact';
    const showWhiteHeader = isBuyPage || isPropertyPage2 || contactPage;


    
  return (
    <div>
      {/* mobile screen */}
      <div className={`flex lg:hidden  w-full xl:text-lg items-center px-3 justify-between      z-30  ${isScrolled || isPropertyPage   ? 'bg-white shadow-md text-[#545350] fixed' : 'bg-transparent text-white fixed'}
      `}>
      <img src={ isScrolled || isPropertyPage   ? balcklogo : logo} width={150} alt='logo' className='' onClick={()=>navigate('/')}></img>
      <button className='p-2  border rounded-lg' onClick={() => setIsOpen(!isOpen)}><IoMdMenu/></button>

      {/* Sidebar */}
      <motion.div
  className="fixed top-0 right-0 w-full h-full bg-white p-6"
  variants={sidebarVariants}
  initial="closed"
  animate={isOpen ? 'open' : 'closed'}
>
  <h2
    className="text-2xl mb-4 text-end font-bold text-gray-500 cursor-pointer"
    onClick={() => setIsOpen(false)} // Close the menu when the "X" is clicked
  >
    x
  </h2>
  <ul className="text-start text-gray-500 text-2xl font-bold">
    <li
      onClick={() => {
        navigate('/buy');
        setIsOpen(false); // Close the menu
      }}
      className={`cursor-pointer ${
        isActive('/buy') ? 'text-gold-400 underline' : ''
      } hover:text-gold-400 transition-colors duration-300`}
    >
      BUY
    </li>
    <li
      onClick={() => {
        navigate('/sell');
        setIsOpen(false); // Close the menu
      }}
      className={`cursor-pointer ${
        isActive('/sell') ? 'text-gold-400 ' : ''
      } hover:text-gold-400 transition-colors duration-300`}
    >
      SELL
    </li>
    <li
      onClick={() => {
        navigate('/ourteam');
        setIsOpen(false); // Close the menu
      }}
      className={`cursor-pointer ${
        isActive('/ourteam') ? 'text-gold-400 underline' : ''
      } hover:text-gold-400 transition-colors duration-300`}
    >
      OUR TEAM
    </li>
    <li
      onClick={() => {
        navigate('/ourstory');
        setIsOpen(false); // Close the menu
      }}
      className={`cursor-pointer ${
        isActive('/ourstory') ? 'text-gold-400 underline' : ''
      } hover:text-gold-400 transition-colors duration-300`}
    >
      OUR STORY
    </li>
    <li
      onClick={() => {
        navigate('/blog');
        setIsOpen(false); // Close the menu
      }}
      className={`cursor-pointer ${
        isActive('/blog') ? 'text-gold-400 underline' : ''
      } hover:text-gold-400 transition-colors duration-300`}
    >
      BLOG
    </li>
    <li
      onClick={() => {
        navigate('/contact');
        setIsOpen(false); // Close the menu
      }}
      className={`cursor-pointer ${
        isActive('/contact') ? 'text-gold-400 underline' : ''
      } hover:text-gold-400 transition-colors duration-300`}
    >
      CONTACT
    </li>
    <li
      onClick={() => {
        openDialog('login');
        setIsOpen(false); // Close the menu
      }}
      className={`cursor-pointer ${
        isActive('/login') ? 'text-gold-400 underline' : ''
      } hover:text-gold-400 transition-colors duration-300`}
    >
      ACCOUNT
    </li>
  </ul>
</motion.div>

    </div>

    <div className={`lg:flex max-lg:hidden   w-full xl:text-lg items-center px-10 justify-between xl:px-20 z-30 fixed 
      ${isScrolled  ? 'bg-white shadow-md' : 'bg-transparent'} 
      ${isPropertyPage ? 'text-black shadow-lg bg-white' : isScrolled ? 'text-[#545350]' : 'text-white'}
      `}


    >
        <div>
        <img src={ isScrolled || isPropertyPage ? balcklogo : logo} width={200} alt='logo' className='xl:w-56 cursor-pointer' onClick={()=>navigate('/')}></img>
        </div>
        <ul className={`flex items-center gap-14 font-semibold text-base`}>
      <li
        onClick={() => navigate('/buy')}
        className={`cursor-pointer ${isActive('/buy') ? 'text-gold-400 underline' : ''}hover:text-gold-400 transition-colors duration-300 `}
      >
        BUY
      </li>
      <li
        onClick={() => navigate('/sell')}
        className={`cursor-pointer ${isActive('/sell') ? 'text-gold-400 ' : ''} hover:text-gold-400 transition-colors duration-300`}
      >
        SELL
      </li>
      <li
        onClick={() => navigate('/ourteam')}
        className={`cursor-pointer ${isActive('/ourteam') ? 'text-gold-400 underline' : ''}hover:text-gold-400 transition-colors duration-300`}
      >
        OUR TEAM
      </li>
      <li
        onClick={() => navigate('/ourstory')}
        className={`cursor-pointer ${isActive('/ourstory') ? 'text-gold-400 underline' : ''}hover:text-gold-400 transition-colors duration-300`}
      >
        OUR STORY
      </li>
      <li
        onClick={() => navigate('/blog')}
        className={`cursor-pointer ${isActive('/blog') ? 'text-gold-400 underline' : ''}hover:text-gold-400 transition-colors duration-300`}
      >
        BLOG
      </li>
      <li
        onClick={() => navigate('/contact')}
        className={`cursor-pointer ${isActive('/contact') ? 'text-gold-400 underline' : ''}hover:text-gold-400 transition-colors duration-300`}
      >
        CONTACT
      </li>
    </ul>

     {isAuthenticated ? ( 
 <div className='p-2 w-60 font-semibold text-base relative'>
    <p className='truncate cursor-pointer' onClick={() => setShowProfile(!showProfie)}>{userName}</p>
    {showProfie && (
      <div className='absolute rounded-md'>
        <ul className='p-2 bg-white text-black rounded-md'>
          <li className='flex items-center gap-1 p-2 border-b border-gray-100 cursor-pointer hover:bg-gray-200'>
            <FaRegUserCircle/> <span>{userName}</span>
          </li>
          <li className='flex items-center gap-1 p-2 border-b border-gray-100 cursor-pointer hover:bg-gray-200' onClick={() => logout()}>
            <FiLogOut/> <span>Sign Out</span>
          </li>
        </ul>
      </div>
    )}
  </div>
 ) : (
  <ul className='flex gap-2 items-center'>
    <li>
      <button
        className={`p-3 rounded-full w-24 xl:w-20 xl:p-2 hover:bg-black hover:text-white ${isScrolled ? 'bg-[#545350] text-white' : 'bg-white text-[#545350]'}`}
        onClick={() => openDialog('login')}
      >
        Sign in
      </button>
    </li>
    <li>
      <button
        className='p-3 bg-white rounded-full text-[#545350] hover:bg-black hover:text-white w-24 xl:w-20 xl:p-2 border border-[#F2CC2F]'
        onClick={() => openDialog('signup')}
      >
        Sign up
      </button>
    </li>
  </ul>
 )} 


      
    </div>
    <AuthDialog
        isOpen={isDialogOpen}
        onClose={closeDialog}
        isLogin={isLogin}
        switchForm={switchForm}
        initialForm={initialForm}
      />
    </div>


    
  );
};

export default HeaderComponent;