import reviewImg1 from "../assets/images/reviewImg.webp";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CustomArrow from '../components/customArrows';
import Slider from "react-slick";
const ReviewContent = () => {
    const reviews = [
        {
            image: reviewImg1,
            positon: "Home Buyer",
            name: "Mona Simpson",
            review: "As a first-time homebuyer, I couldn't have asked for a better experience with J&D. Their team guided me seamlessly through every step of the process, from finding the perfect location to securing financing. Their attention to detail and dedication to customer satisfaction truly set them apart. I'm thrilled with my new home and grateful forJ&D's expertise and support.",
        },
        {
            image: reviewImg1,
            positon: "Designer",
            name: "Nancy Doe",
            review: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.The test Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
        }
    ];
    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    return (
        <div className="p-10 pb-0 bg-Amber-50 max-sm:p-2 max-sm:mt-20 max-lg:p-0">
        <div>
                <h1 className="text-[45px] text-center">Testimonials</h1>
            </div>
        <div className="px-10 py-10 flex w-full  max-sm:flex max-sm:flex-col max-sm:p-0 max-lg:px-5">
            <div className="w-1/2 text-start float-left flex flex-col gap-6 p-5 max-sm:w-full">
                <h1 style={{ fontFamily: '"Bodoni Moda", "Sans-serif"', color:"black"}} className="text-[45px] max-sm:text-[35px]">What our customers are saying us?</h1>
          
      
         
    
                <p className="text-lg">Various versions have evolved over the years, sometimes by <br/>accident, sometimes on purpose injected humour
                <br/>and the like.</p>

                <div className="flex justify-around">
                    <div className="basis-1/2 float-left">
                        <h1 style={{fontSize:"20px",fontWeight:"500px"}}>30+ years</h1>
                        <span className="mt-7" style={{color:"#464646",fontSize:"14px"}} >of Real Estate Experience</span>
                    </div>
                    <div className="basis-1/2 float-left">
                        <h1 style={{fontSize:"20px",fontWeight:"500px"}}>2012-2024</h1>
                        <span className="mt-7" style={{color:"#464646",fontSize:"14px"}}> Realtors®️ Sales Award</span>
                    </div>
                </div>
            </div>
            <div className='w-1/2 float-right p-5 max-sm:w-full'>
                <Slider {...settings}>
                    {reviews.map((item, index) => (
                        <div key={index} className="flex gap-6">
                            <div className="flex items-center gap-6">
                                <img src={item.image} alt="review" className="object-cover w-20 rounded-full"></img>
                                <div className="flex flex-col text-start">
                                    <p style={{color:"black",fontFamily:'"Roboto", "Sans-serif"',fontSize:"24px", fontWeight:"500"}} className="">{item.name}</p>
                                    <span style={{color:"black",fontFamily:'"Roboto", "Sans-serif"',fontSize:"16px", fontWeight:"4 00"}} className="text-xl">{item.positon}</span>
                                </div>
                            </div>
                            <p style={{color:"#464646",fontFamily:'"Open Sans", "Sans-serif"',fontSize:"16px", fontWeight:"400",lineHeight:"1.8em"}} className="mt-10 leading-9 text-start ">{item.review}</p>
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
        </div>
    );
};
export default ReviewContent;