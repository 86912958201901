import React from 'react'
import "../../App.css"

function Accessibility3() {
  return (
    <div className='pt-24 mx-4 sm:mx-8 md:mx-16 lg:mx-48'>
        <div>
            <h2 className='text-center flex flex-col items-center gap-6 mb-6 mt-10 font-bold text-[28px] sm:text-[32px] md:text-[36px]'>Integrated Accessibility Standards Policy</h2>
        </div>

        <div className='single-content'>
            <p>The following policy has been established by Royal LePage Real Estate Services Ltd. (the Company) to govern the provision of services with Regulation 191/11, “Integrated Accessibility Standards” (“Regulation”) under the&nbsp;</p>
            <p>These standards are developed to break down barriers and increase accessibility for persons with disabilities in the areas of information and communications and employment.</p>
            <p>The Company is governed by this policy as well as the Accessibility Standards for Customer Service Policy and the&nbsp;<i>Accessibility for Ontarians with Disabilities Act, 2005</i>&nbsp;in meeting the accessibility needs of persons with disabilities.</p>
            <h2 className='text-start font-bold text-customBlue mb-3 text-lg sm:text-xl md:text-2xl'>Commitment</h2>
            <p>The Company is committed to treating all people in a way that allows them to maintain their dignity and independence. We believe in integration and equal opportunity. We are committed to meeting the needs of persons with disabilities in a timely manner, and will do so by preventing and removing barriers to accessibility and meeting accessibility requirements under the Accessibility for Ontarians with Disabilities Act.</p>
            <p>This policy will be implemented in accordance with the time frames established by the Regulation.</p>
            <h2 className='text-start font-bold text-customBlue mb-3 text-lg sm:text-xl md:text-2xl'>Accessibility Plan</h2>
            <p>The Company will develop, maintain and document an Accessibility Plan outlining the company’s strategy to prevent and remove barriers from its workplace and to improve opportunities for persons with disabilities.</p>
            <p>The Accessibility Plan will be reviewed and updated at least once every five years, and will be posted on the company’s website. Upon request, the Company will provide a copy of the Accessibility Plan in an accessible format.</p>
            <h2 className='text-start font-bold text-customBlue mb-3 text-lg sm:text-xl md:text-2xl'>Training Employees and Volunteers</h2>
            <p>The Company will ensure that training is provided on the requirements of the accessibility standards referred to in the Regulation and continue to provide training on the Human Rights Code as it pertains to persons with disabilities, to:</p>
            <ul className='text-start list-disc pl-6'>
                <li>all its employees and volunteers;</li>
                <li>all persons who participate in developing the Company policies; and,</li>
                <li>all other persons who provide goods, services or facilities on behalf of the company</li>
            </ul>
            <p>The training will be appropriate to the duties of the employees, volunteers and other persons.</p>
            <p>Employees will be trained when changes are made to the accessibility policy. New employees will be trained during their first week of employment. The Company will keep a record of the training it provides.</p>
            <h4 className='font-bold text-start mb-3 text-lg sm:text-xl md:text-2xl'>INFORMATION AND COMMUNICATIONS STANDARDS</h4>
            <h2 className='text-start font-bold text-customBlue mb-3 text-lg sm:text-xl md:text-2xl'>Feedback</h2>
            <p>The Company will continue to ensure that its process for receiving and responding to feedback is accessible to persons with disabilities by providing, or arranging for the provision of, accessible formats and communications supports, upon request.</p>
            <h2 className='text-start font-bold text-customBlue mb-3 text-lg sm:text-xl md:text-2xl'>Accessible Formats and Communication Supports</h2>
            <p>Upon request, the Company will provide, or will arrange for the provision of accessible formats and communication supports for persons with disabilities in a timely manner that takes into account the person’s accessibility needs due to disability.</p>
            <p>The Company will consult with the person making the request in determining the suitability of an accessible format or communication support.</p>
            <p>The Company will also notify the public about the availability of accessible formats and communication supports.</p>
            <h2 className='text-start font-bold text-customBlue mb-3 text-lg sm:text-xl md:text-2xl'>Accessible Websites and Web Content</h2>
            <p>The Company will ensure that our Internet websites, including web content, conform to the World Wide Web Consortium Web Content Accessibility Guidelines (WCAG) 2.0, at Level AA except where this is impracticable.</p>
            <h4 className='font-bold text-start mb-3 text-lg sm:text-xl md:text-2xl'>EMPLOYMENT STANDARDS</h4>
            <h2 className='text-start font-bold text-customBlue mb-3 text-lg sm:text-xl md:text-2xl'>Recruitment</h2>
            <p>The Company will notify its employees and the public about the availability of accommodation for applicants with disabilities in its recruitment process.</p>
            <h2 className='text-start font-bold text-customBlue mb-3 text-lg sm:text-xl md:text-2xl'>Recruitment, Assessment or Selection Process</h2>
            <p>The Company will notify job applicants, when they are individually selected to participate further in an assessment or selection process that accommodations are available upon request in relation to the materials or processes to be used.</p>
            <p>If a selected applicant requests an accommodation, the Company will consult with the applicant and provide, or arrange for the provision of, a suitable accommodation in a manner that takes into account the applicant’s accessibility needs due to disability.</p>
            <h2 className='text-start font-bold text-customBlue mb-3 text-lg sm:text-xl md:text-2xl'>Notice to Successful Applicants</h2>
            <p>When making offers of employment, the Company will notify the successful applicant of its policies for accommodating employees with disabilities.</p>
            <h2 className='text-start font-bold text-customBlue mb-3 text-lg sm:text-xl md:text-2xl'>Informing Employees of Supports</h2>
            <p>The Company will continue to inform its employees of its policies (and any updates to those policies) used to support employees with disabilities, including policies on the provision of job accommodations that take into account an employee’s accessibility needs due to disability. This information will be provided to new employees as soon as practicable after commencing employment.</p>
            <h2 className='text-start font-bold text-customBlue mb-3 text-lg sm:text-xl md:text-2xl'>Accessible Formats and Communication Supports for Employees</h2>
            <p>Upon the request of an employee with a disability, the Company will consult with the employee to provide, or arrange for the provision of, accessible formats and communication supports for information that is needed to perform his/her job, and information that is generally available to other employees. In determining the suitability of an accessible format or communication support, the Company will consult with the employee making the request.</p>
            <h2 className='text-start font-bold text-customBlue mb-3 text-lg sm:text-xl md:text-2xl'>Workplace Emergency Response Information</h2>
            <p>The Company will provide individualized workplace emergency response information to employees who have a disability, if the disability is such that the individualized information is necessary, and if the Company is aware of the need for accommodation due to the employee’s disability. The Company will provide this information as soon as practicable after becoming aware of the need for accommodation.</p>
            <p>Where the employee requires assistance, the Company will, with the consent of the employee, provide the workplace emergency response information to the person designated by the Company to provide assistance to the employee.</p>
            <p>The Company will review the individualized workplace emergency response information when the employee moves to a different location in the organization, when the employee’s overall accommodations needs or plans are reviewed.</p>
            <h2 className='text-start font-bold text-customBlue mb-3 text-lg sm:text-xl md:text-2xl'>Documented Individual Accommodation Plans</h2>
            <p>The Company will maintain a written process for the development of documented individual accommodation plans for employees with disabilities.</p>
            <p>If requested, information regarding accessible formats and communications supports provided will also be included in individual accommodation plans.</p>
            <p>In addition, the plans will include individualized workplace emergency response information (where required), and will identify any other accommodation that is to be provided.</p>
            <h2 className='text-start font-bold text-customBlue mb-3 text-lg sm:text-xl md:text-2xl'>Return to Work Process</h2>
            <p>The Company maintains a documented return to work process for its employees who have been absent from work due to a disability and who require disability-related accommodations in order to return to work. The return to work process outlines the steps the Company will take to facilitate the return to work and will include documented individual accommodation plans as part of the process. This return to work process will not replace or override any other return to work process created by or under any other statute (ie.,<i>Workplace Safety Insurance Act, 1997</i>) the&nbsp;</p>
            <h2 className='text-start font-bold text-customBlue mb-3 text-lg sm:text-xl md:text-2xl'>Performance Management, Career Development and Advancement & Redeployment</h2>
            <p>The Company will take into account the accessibility needs of employees with disabilities, as well as individual accommodation plans, when conducting performance management, providing career development and advancement to employees, or when redeploying employees.</p>
            <h2 className='text-start font-bold text-customBlue mb-3 text-lg sm:text-xl md:text-2xl'>Questions about this policy</h2>
            <p>This policy has been developed to break down barriers and increase accessibility for persons with disabilities in the areas of information and communications and employment. If anyone has a question about the policy, or if the purpose of a policy is not understood, an explanation will be provided by:</p>
            <p>Aideen Kennedy</p>
            <p>Human Resources Manager</p>
            <p>416.510.5686</p>
            <p><span className='text-blue-600'>aideen.kennedy@brookfieldres.com</span></p>

        </div>

    </div>
  )
}

export default Accessibility3
