import React, { useState, useEffect } from 'react';
import { FaEnvelope, FaLock, FaUser } from 'react-icons/fa';
import { FcGoogle } from 'react-icons/fc';
import { IoClose } from 'react-icons/io5';
import { googleLogin, signInService, loginService } from '../service';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAuth } from '../hooks/AuthContext';
import { FaRegEyeSlash } from "react-icons/fa";
import { FaRegEye } from "react-icons/fa";

const AuthDialog = ({ isOpen, onClose, initialForm = 'login' }) => {
  const { login, logout } = useAuth();
  const [isLogin, setIsLogin] = useState(initialForm === 'login' || initialForm === 'signin');
  const [loginFormdata, setLoginFormData] = useState({
    email: '',
    password: '',
  });
  const [signInFormData, setsignInFormData] = useState({
    name:'',
    email: '',
    password: '',
    confirm_pass: '',
  });
  const [error, setError] = useState()
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  useEffect(() => {
    setIsLogin(initialForm === 'login' || initialForm === 'signin');
  }, [initialForm]);

  if (!isOpen) return null;

  const toggleForm = () => setIsLogin(!isLogin);
  console.log(isLogin,"islogin")

  // Handle Google login success
  const handleLoginSuccess = async (googleToken) => {
    try {
      const data = { token: googleToken };
      if (isLogin) {
        const response = await loginService(data);
        console.log('Login success:', response);
      } else {
        const response = await signInService(data);
        console.log('Sign-up success:', response);
      }
    } catch (error) {
      console.error('Google login failed:', error.message);
    }
  };

  // Handle Google login failure
  const handleLoginFailure = (error) => {
    console.error('Google login failed', error);
  };
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (isLogin) {
      const loginPayload = {
        email: loginFormdata.email,
        password: loginFormdata.password
      }
      try {
        const response = await loginService(loginPayload);
        console.log(response,"responsive");
        if (response)
        login()
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('user', loginFormdata.email)
        toast.success('Login Succefully', {
          position: "top-right",
          autoClose: 3000,
        })
        setTimeout(() => {
          onClose();

        }, 3000)
        // if(response.)

      } catch (error) {
        console.log("error", error)
        toast.error(`${error.message}`, {
          position: "top-right",
          autoClose: 3000,
        })
        console.error('Login failed:', error);
      } finally {
        setLoginFormData({email:'', password:''})
      }
    } else {
      if (!validateEmail(signInFormData.email)) {
        console.log(validateEmail(signInFormData.email))
        return setError('email')
      }
      const signInPayload = {
        username: signInFormData.name,
        email: signInFormData.email,
        password: signInFormData.password
      }
      try {
        const response = await signInService(signInPayload);
        
        if(response){
          toast.success('User Created SuccessFully', {
            position: 'top-right',
            autoClose: 2000
          })
          setIsLogin(!isLogin)
        }
        console.log('Sign-up response:', response);
      } catch (error) {
        console.error('Sign-up failed:', error.message);
      } finally {
        setsignInFormData({ email: '', password: '', confirm_pass:'', name: ''})
      }
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md relative">
        <button onClick={onClose} className="absolute top-3 right-3 text-gray-400 hover:text-gray-600">
          <IoClose />
        </button>

        <h2 className="text-2xl font-semibold text-gray-800 text-start mb-1">
          {isLogin ? 'Welcome back' : 'Welcome to J&D'}
        </h2>
        <p className="text-start text-gray-500 mb-6">
          {isLogin ? 'Enter your credentials and get ready to explore!' : 'Create an account to join us!'}
        </p>

        {/* Form */}
        <form onSubmit={handleFormSubmit}>
          {/* Name input for Sign Up */}
          {!isLogin && (
            <div className="mb-4 relative">
              <FaUser className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
              <input
                type="text"
                placeholder="Your Name"
                className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-Stone-500"
                value={signInFormData.name}
                onChange={(e) => setsignInFormData({ ...signInFormData, name: e.target.value })}
              />
            </div>
          )}

          {/* Email Input */}
          <div className="mb-4 relative">
            <FaEnvelope className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            <input
              type="email"
              placeholder="Your Email"
              className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-Stone-500"
              value={isLogin ? loginFormdata.email : signInFormData.email}
              onChange={(e) => {
                if (isLogin) {
                  setLoginFormData({ ...loginFormdata, email: e.target.value });
                } else {
                  setsignInFormData({ ...signInFormData, email: e.target.value });
                }
              }}
            />

          </div>
          {error === 'email' && (
            <p className='text-xs text-start text-Red-600'>*enter a valid maild id</p>
          )}

          {/* Password Input */}
          <div className="mb-4 relative">
  <FaLock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
  <input
    type={showPassword ? 'text' : 'password'}
    placeholder="Your Password"
    className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-Stone-500"
    value={isLogin ? loginFormdata.password : signInFormData.password}
    onChange={(e) => {
      if (isLogin) {
        setLoginFormData({ ...loginFormdata, password: e.target.value });
      } else {
        setsignInFormData({ ...signInFormData, password: e.target.value });
      }
    }}
  />
  <div onClick={() => setShowPassword(!showPassword)} className="cursor-pointer absolute right-3 top-1/2 transform -translate-y-1/2">
    {showPassword ? <FaRegEyeSlash className='text-gray-400'/> : <FaRegEye className='text-gray-400'/>}
  </div>
</div>


          {/* Confirm Password for Sign Up */}
          {!isLogin && (
            <div className="mb-4 relative">
            <FaLock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            <input
              type={showConfirmPassword ? 'text' : 'password'}
              placeholder="Confirm Password"
              className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-Stone-500"
              value={signInFormData.confirm_pass}
              onChange={(e) =>
                setsignInFormData({ ...signInFormData, confirm_pass: e.target.value })
              }
            />
            <div
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              className="cursor-pointer absolute right-3 top-1/2 transform -translate-y-1/2"
            >
              {showConfirmPassword ? <FaRegEyeSlash className='text-gray-400'/> : <FaRegEye className='text-gray-400'/>}
            </div>
          </div>
          )}

          {/* Submit Button */}
          <button
            type="submit"
            className="w-full py-2 mb-4 font-semibold text-white bg-Stone-600 rounded hover:bg-Stone-700"
          >
            {isLogin ? 'Sign in' : 'Sign up'}
          </button>

          {/* Google Login Button */}
          <div>
            <GoogleOAuthProvider clientId="YOUR_GOOGLE_CLIENT_ID">
              <GoogleLogin
                onSuccess={(response) => handleLoginSuccess(response.credential)}
                onError={handleLoginFailure}
                useOneTap
              />
            </GoogleOAuthProvider>
          </div>
        </form>

        {/* Toggle between Log In and Sign Up */}
        <p className="text-center text-gray-600 mt-6">
          {isLogin ? (
            <>
              Doesn’t have an account?{' '}
              <button onClick={toggleForm} className="text-blue-500 hover:underline">
                Sign up
              </button>
            </>
          ) : (
            <>
              Already have an account?{' '}
              <button onClick={toggleForm} className="text-blue-500 hover:underline">
                Sign in
              </button>
            </>
          )}
        </p>
      </div>
      <ToastContainer />
    </div>
  );
};

export default AuthDialog;
