import { IoBedOutline } from "react-icons/io5";
import { LiaBathSolid } from "react-icons/lia";
import React, { useContext, useState } from 'react';
import ListView from '../components/listview';
import MapComponent from '../components/map';
import { FaAngleDown } from "react-icons/fa6";
import { CiFilter } from "react-icons/ci";
import DialogModal from "../components/photosDialog";
import { FilterContext } from '../components/FilterContext';
import Slider from 'rc-slider';
import { IoSearchOutline } from "react-icons/io5";
import 'rc-slider/assets/index.css';
const Buypage = () => {
    const properties = [
        'All Property Types',
        "Detached",
        "Semi-Detached",
        "Condo Apt",
        "Condo Townhouse",
        "Duplex",
        "Multiplex",
        "Vacant Land",
        "Farm",
        
    ];

    const {
        propertyType,
        setPropertyType,
        location,
        setLocation,
        price,
        setPrice,
        minPrice,
        setMinPrice,
        maxPrice,
        setMaxPrice,
        bedrooms,
        setBedrooms,
        bathrooms,
        setBathrooms,
        mlsNumber,
        setMlsNumber
    } = useContext(FilterContext);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [activeView, setActiveView] = useState('list');
    const [showPriceRange, setShowPriceRange] = useState(false);
    const [showBedrooms, setShowBedrooms] = useState(false);
    const [showBaths, setShowBaths] = useState(false);
    const [searchquery, setSearchQuery] = useState('');
    const [searchTrue, setSearchTrue] = useState(false);

    const handleRangeChange = (value) => {
        setPrice(value);
        
      };
      const handleAfterChange = (value) => {

        setMinPrice(value[0]);
        setMaxPrice(value[1]);
      };

    const handleToggle = (view) => {
        setActiveView(view);
    };

    const toggleView = (view) => {
        if (view === 'price') {
            setShowPriceRange(!showPriceRange);
            setShowBedrooms(false);
            setShowBaths(false);
            setIsModalOpen(false);
        } else if (view === 'beds') {
            setShowBedrooms(!showBedrooms);
            setShowPriceRange(false);
            setShowBaths(false);
            setIsModalOpen(false);
        } else if (view === 'baths') {
            setShowBaths(!showBaths);
            setShowPriceRange(false);
            setShowBedrooms(false);
            setIsModalOpen(false);
        } else if (view === 'filter') {
            setIsModalOpen(!isModalOpen);
            setShowPriceRange(false);
            setShowBedrooms(false);
            setShowBaths(false);
        }
    };

    const handleClickOutside = (event) => {
        if (!event.target.closest('.relative')) {
            setShowPriceRange(false);
            setShowBedrooms(false);
            setShowBaths(false);
            setIsModalOpen(false);
        }
    };

    React.useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    const isAnyFilterSet = () => {
        return propertyType || location || price[1] !== 3200000 || minPrice || maxPrice || bedrooms || bathrooms;
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleSearch = (e) => {
        e.preventDefault();
        setMlsNumber(searchquery);
        setSearchTrue(true);
        console.log(mlsNumber);
    };
    const getSearchQuery = () => {
        
        // Perform your search logic here
        setSearchTrue(false); // Reset the condition to false
      };
    const handleSetBathroomsAndToggleView = (bath) => {
        if(bath === 'All'){
            bath = null;

        }
        setBathrooms(bath);
        toggleView('baths');
    };
    const handleSetBedroomsAndToggleView = (bed) => {
        if(bed === 'All'){
            bed = null; 
        }
        setBedrooms(bed);
        toggleView('beds');
    };

    return (
        <div className='bg-Stone-100'>
            <div className='relative w-full'>
                {/* <div className='absolute inset-0 z-10'>
                    <img src={Img} alt="buy" className='opacity-70 w-full h-full object-cover' />
                </div> */}
                <div className='pt-32'>
                <p className="text-4xl font-bold">Explore all Feature Properties</p>
                    <p className="text-lg">Explore our extensive range of properties and find your perfect home.</p>
                </div>
                   
                <div className='mt-8 rounded-2xl inset-0 flex h-16 bg-white top-[64%] mx-auto my-0 z-30 w-[30%]'>
                    <button
                        className={`p-2 font-semibold rounded-l-2xl w-full ${activeView === 'list' ? 'bg-[#545350] text-white' : 'bg-gray-200 text-black'}`}
                        onClick={() => handleToggle('list')}
                    >
                        List View
                    </button>
                    <button
                        className={`p-2 font-semibold rounded-r-2xl w-full ${activeView === 'map' ? 'bg-[#545350] text-white' : 'bg-gray-200'}`}
                        onClick={() => handleToggle('map')}
                    >
                        Map View
                    </button>
                </div>
                <div className='w-full px-3 flex flex-col pt-4'>
                    <div className='flex flex-col p-2  bg-white gap-4 box-border rounded-2xl z-30'>
                        <div className='flex gap-5 justify-between'>
                            <div className='flex items-center gap-2'>
                            <div className='w-56 p-1 border border-gray-300 flex justify-around rounded-xl items-center'>
                                <input
                                    type="text"
                                    placeholder="location"
                                    className="w-full p-1 outline-none"
                                    value={location}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />
                                <IoSearchOutline size={24}/>
                            </div>
                            <div className='w-44 p-1 border border-gray-300 rounded-xl flex items-center gap-2 cursor-pointer'>
                                <select
                                    className="p-2 w-full outline-none"
                                    value={propertyType}
                                    onChange={(e) => setPropertyType(e.target.value)}
                                >
                                    <option disabled>Property Type</option>
                                    {properties.map((property, index) => (
                                        <option key={index} value={property}>{property}</option>
                                    ))}
                                </select>
                            </div>
                            
                            <div className='flex gap-4'>
                                <div className='relative'>
                                    <div className='p-2 border border-gray-300 rounded-xl flex items-center gap-2 cursor-pointer' onClick={() => toggleView('price')}>
                                        <p className='text-base'>Price</p>
                                        <FaAngleDown />
                                    </div>
                                    {showPriceRange && (
                                        <div className='absolute p-2 bg-white top-[100%] border w-96 border-gray-300 rounded-lg shadow-lg'>
                                            <div>
                                                <label
                                                    htmlFor="medium-range"
                                                    className="block mb-2 text-sm font-medium text-black"
                                                >
                                                    Price range
                                                </label>
                                                <Slider
                                                    range
                                                    min={0}
                                                    max={3200000}
                                                    value={price}
                                                    onChange={handleRangeChange}
                                                    onAfterChange={handleAfterChange}
                                                    className="w-full h-2 mb-6 rounded-lg appearance-none cursor-pointer"
                                                />
                                                <div className="flex justify-between mt-2 text-gray-700">
                                                    <span>${price[0].toLocaleString()}</span>
                                                    <span>${price[1].toLocaleString()}</span>
                                                </div>
                                            </div>

                                        </div>
                                    )}
                                </div>
                                <div className="relative">
                                    <div
                                        className="p-2 border border-gray-300 rounded-xl flex items-center gap-2 cursor-pointer"
                                        onClick={() => toggleView('beds')}
                                    >
                                        <IoBedOutline size={16}/>
                                        <p className="text-base">{bedrooms ? `${bedrooms} ` :'All' } </p>
                                        
                                        <FaAngleDown />
                                    </div>
                                    {showBedrooms && (
                                        <div className="flex w-44 flex-col absolute p-1 bg-white top-[100%] left-0 border border-gray-300 rounded-lg shadow-lg z-10">
                                            {['All',1, 2, 3, 4, '5+'].map((bed, index) => (
                                                <div
                                                    key={index}
                                                    className="flex items-center border-b border-gray-200 py-1 gap-3 cursor-pointer hover:bg-gray-100"
                                                    onClick={() => handleSetBedroomsAndToggleView(bed)}
                                                >
                                                    <IoBedOutline />
                                                    <span>{bed}</span>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                                <div className="relative">
                                    <div
                                        className="p-2 border border-gray-300 rounded-xl flex items-center gap-2 cursor-pointer"
                                        onClick={() => toggleView('baths')}
                                    >
                                        <LiaBathSolid />
                                        <p className="text-base">{bathrooms ? `${bathrooms}` : 'All'}</p>
                                        <FaAngleDown />
                                    </div>
                                    {showBaths && (
                                        <div className="flex w-44 flex-col absolute p-1 bg-white top-[100%] left-0 border border-gray-300 rounded-lg shadow-lg z-30">
                                            {['All',1, 2, 3, 4, '5+'].map((bath, index) => (
                                                <div
                                                    key={index}
                                                    className="flex items-center border-b border-gray-200 py-1 gap-3 cursor-pointer hover:bg-gray-100"
                                                    onClick={() => handleSetBathroomsAndToggleView(bath)}

                                                >
                                                    <LiaBathSolid />
                                                    <span>{bath}</span>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </div>
                            </div>
                            <div className='flex justify-between'>
                            
                            <div className=''>
                                <div className={`p-2 border relative border-gray-300 rounded-xl flex items-center gap-2 cursor-pointer ${isAnyFilterSet() && ''}`} onClick={() => toggleView('filter')}>
                                    <p className='text-base'>Filters</p>
                                    <CiFilter size={16} className=''/>
                                    

                                </div>
                            </div>
                            <DialogModal isOpen={isModalOpen} onClose={closeModal} value={''} index={''} title={'filter'} />
                        </div>
                           
                        </div>
                        
                    </div>
                    <div className='flex p-2 top-[98%] z-30 absolute'>
                    </div>
                </div>
            </div>
            {activeView === 'list' && (
                <ListView
                    propertyType={propertyType}
                    location={location}
                    priceRange={price}
                    minPrice={minPrice}
                    maxPrice={maxPrice}
                    beds={bedrooms}
                    baths={bathrooms}
                    mlsNumber={mlsNumber}
                    handleSearchTrue={searchTrue} 
                    getSearchQuery={getSearchQuery}
                />
            )}
            {activeView === 'map' && (
                <div className='mt-5 h-[500px]'><MapComponent
                    propertyType={propertyType}
                    location={location}
                    priceRange={price}
                    minPrice={price[0]}
                    maxPrice={price[1]}
                    beds={bedrooms}
                    baths={bathrooms}
                /></div>
            )}
        </div>
    );
};

export default Buypage;