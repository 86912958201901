import React, { useState } from "react";

// Sample data for branches
const branches = [
    {
        id: 1,
        name: "Toronto",
        address: "477 Mount Pleasant Road Toronto, ON, M4S 2L9",
        phone: "(416) 489-2121",
        email: "info@johnstonanddaniel.com",
        hours: "Mon-Fri: 9am - 5pm",
        map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2884.4609741885283!2d-79.3897402151023!3d43.700968389820936!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b3336bb892f4b%3A0x33e43a19f0af0df7!2sJohnston%20%26%20Daniel%2C%20A%20Division%20of%20Royal%20LePage%20Real%20Estate%20Services%20Ltd.%2C%20Brokerage!5e0!3m2!1sen!2sin!4v1729685343366!5m2!1sen!2sin",
    },
    {
        id: 2,
        name: "Oakville",
        address: "326 Lakeshore Road East, Suite B Oakville, ON, L6J 1M9",
        phone: "(705) 765-6855",
        email: "info@johnstonanddaniel.com",
        hours: "Mon-Fri: 9am - 5pm",
        map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11586.374816848896!2d-79.68368481956693!3d43.44820089479462!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b5d8b10e144bd%3A0x7893fece5d72abca!2sJohnston%20%26%20Daniel%20Oakville%2C%20A%20Division%20of%20Royal%20LePage%20Real%20Estate%20Services%20Ltd.%2C%20Brokerage!5e0!3m2!1sen!2sin!4v1729684805207!5m2!1sen!2sin",
    },
    {
        id: 3,
        name: "Muskoka",
        address: "118 Medora Street, Box 338 Port Carling, ON, P0B 1J0",
        phone: "(705) 765-6855",
        email: "info@johnstonanddaniel.com",
        hours: "Mon-Fri: 9am - 5pm",
        map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d180509.18996984203!2d-79.90296363830566!3d45.01566604033983!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4d2af3aaea8b00b5%3A0x1de093ad905cd4c2!2sJohnston%20%26%20Daniel%20Rushbrooke%20Realty!5e0!3m2!1sen!2sin!4v1729685121352!5m2!1sen!2sin",
    },
    {
        id: 4,
        name: "Kawarthas",
        address: "3267 Fire Route 50, Box 1293 Lakefield, ON, K0L 2H0",
        phone: "(705) 652-5000",
        email: "info@johnstonanddaniel.com",
        hours: "Mon-Fri: 9am - 5pm",
        map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2849.2587837866076!2d-78.27029102527915!3d44.4278536019878!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cd4b5c67a1a4969%3A0xefb9f42936c4e712!2sRoyal%20LePage%2FJohnston%20%26%20Daniel%20Division%2C%20Lakefield!5e0!3m2!1sen!2sin!4v1729685217463!5m2!1sen!2sin",
    },
];
const faqData = [
    { question: "What are the steps to buy a house?", answer: "Answer to steps for buying a house." },
    { question: "Are there any extra costs to buy a house?", answer: "Answer to extra costs." },
    { question: "How much do I have to pay up-front?", answer: "Answer to upfront payment." },
    { question: "Does the house come with amenities?", answer: "Answer to amenities." },
  ];

const ContactUs = () => {
    const [selectedBranch, setSelectedBranch] = useState(branches[0]);
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        message: "",
    });
    const [formError, setFormError] = useState("");
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
      };

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Simple validation for form fields
        if (
            !formData.firstName ||
            !formData.lastName ||
            !formData.email ||
            !formData.phone ||
            !formData.message
        ) {
            setFormError("Please fill out all fields.");
        } else {
            setFormError("");
            setIsSubmitted(true);
        }
        console.log("dkfnvsdkjfnvhbsd")
    };

    return (
        <div
            className="p-8 bg-Stone-100  pt-32 section-padding bg-cover bg-center font-roboto"
        >
            <div className="text-center mb-8">
                <h1 className="text-4xl font-bold text-gray-800">Contact</h1>
                <p className="text-lg text-gray-600 mt-4">
                    We have branches across Canada. Select a branch to see its details.
                </p>
            </div>

            {/* Branch Selector */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-10">
                {branches.map((branch) => (
                    <div
                        key={branch.id}
                        onClick={() => setSelectedBranch(branch)}
                        className={`p-6 rounded-lg border border-gray-400 transition-all duration-200 ${selectedBranch.id === branch.id
                                ? "bg-Stone-600 text-white"
                                : "bg-gray-100 text-gray-800"
                            } cursor-pointer hover:bg-gray-200 hover:text-gray-800 branch-box ${selectedBranch.id === branch.id ? "branch-box-active" : ""
                            }`}
                    >
                        <h2 className="text-xl font-semibold">{branch.name}</h2>
                    </div>
                ))}
            </div>

            {/* Selected Branch Details */}
            <div className="bg-white p-6 rounded-lg">
                <h2 className="text-2xl font-semibold text-gray-800 mb-4">
                    {selectedBranch.name} Details
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6 text-xl">
                    {/* Branch Information */}
                    <div className="flex flex-col justify-center gap-4 items-center">
                        <p className="text-gray-700">
                            <strong>Address:</strong> {selectedBranch.address}
                        </p>
                        <p className="text-gray-700">
                            <strong>Phone:</strong> {selectedBranch.phone}
                        </p>
                        <p className="text-gray-700">
                            <strong>Email:</strong> {selectedBranch.email}
                        </p>
                        <p className="text-gray-700">
                            <strong>Office Hours:</strong> {selectedBranch.hours}
                        </p>
                    </div>

                    {/* Google Map Embed */}
                    <div className="responsive-iframe">
                        <iframe
                            src={selectedBranch.map}
                            className="w-full h-64 rounded-lg"
                            allowFullScreen=""
                            loading="lazy"
                            title={selectedBranch.name}
                        ></iframe>
                    </div>
                </div>

                {/* Contact Form */}
                <div className="p-20">
                    <h1 className="text-start text-xl font-semibold">
                        Got Questions? We Have Answers!
                    </h1>

                    {isSubmitted ? (
                        <div className="p-4 bg-green-100 text-green-800">
                            Thank you for contacting us! We will get back to you shortly.
                        </div>
                    ) : (
                        <form
                            onSubmit={handleSubmit}
                            className="grid grid-cols-1 md:grid-cols-2 gap-6 shadow-md p-8 mt-5"
                        >
                            {/* Error Message */}
                            {formError && (
                                <div className="md:col-span-2 p-4 bg-red-100 text-red-800">
                                    {formError}
                                </div>
                            )}

                            {/* First Name and Last Name */}
                            <div>
                                <input
                                    type="text"
                                    name="firstName"
                                    value={formData.firstName}
                                    onChange={handleInputChange}
                                    className="w-full p-4 border border-gray-300 rounded-lg bg-gray-50 focus:outline-none focus:ring-2 focus:ring-black transition-all"
                                    placeholder="First Name"
                                />
                            </div>
                            <div>
                                <input
                                    type="text"
                                    name="lastName"
                                    value={formData.lastName}
                                    onChange={handleInputChange}
                                    className="w-full p-4 border border-gray-300 rounded-lg bg-gray-50 focus:outline-none focus:ring-2 focus:ring-black transition-all"
                                    placeholder="Last Name"
                                />
                            </div>

                            {/* Email and Phone */}
                            <div>
                                <input
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                    className="w-full p-4 border border-gray-300 rounded-lg bg-gray-50 focus:outline-none focus:ring-2 focus:ring-black transition-all"
                                    placeholder="Your Email"
                                />
                            </div>
                            <div>
                                <input
                                    type="tel"
                                    name="phone"
                                    value={formData.phone}
                                    onChange={handleInputChange}
                                    className="w-full p-4 border border-gray-300 rounded-lg bg-gray-50 focus:outline-none focus:ring-2 focus:ring-black transition-all"
                                    placeholder="Your Phone"
                                />
                            </div>

                            {/* Message */}
                            <div className="md:col-span-2">
                                <textarea
                                    name="message"
                                    value={formData.message}
                                    onChange={handleInputChange}
                                    className="w-full p-4 border border-gray-300 rounded-lg bg-gray-50 focus:outline-none focus:ring-2 focus:ring-black transition-all"
                                    placeholder="Message"
                                    rows="5"
                                ></textarea>
                            </div>

                            {/* Submit Button */}
                            <div className="md:col-span-2">
                                <button
                                    type="submit"
                                    className="w-full bg-Stone-600 text-white p-4 rounded-lg hover:bg-Stone-400 transition-transform transform"
                                >
                                    SUBMIT NOW
                                </button>
                            </div>
                        </form>
                    )}
                </div>

                <div className="bg-Stone-100 p-10">
      <h1 className="text-3xl font-semibold text-center mb-8">Frequently Asked Questions</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {faqData.map((item, index) => (
          <div key={index} className="bg-white p-6 rounded-lg shadow-md">
            <div
              className="flex justify-between items-center cursor-pointer"
              onClick={() => toggleAccordion(index)}
            >
              <h2 className="text-lg font-semibold">{item.question}</h2>
              <span className="text-2xl font-bold text-gray-500">
                {activeIndex === index ? "-" : "+"}
              </span>
            </div>
            {activeIndex === index && (
              <p className="mt-4 text-gray-600">{item.answer}</p>
            )}
          </div>
        ))}
      </div>
    </div>
            </div>
        </div>
    );
};

export default ContactUs;
