import axios from 'axios';

const BASE_URL = 'https://jd.vto.homes/api';
console.log(BASE_URL,"BASE_URL");
//  const BASE_URL = 'https://23.236.50.58/api'
// const BASE_URL = 'http://localhost:5000';


export const fetchRequest = async (url, method = 'GET', data = null) => {
  try {
    const config = {
      method: method,
      url: url,
      data: data,
    };

    const response = await axios(config);
    return response;
  }  catch (error) {
    if (error.response) {
      throw {
        status: error.response.status,
        message: error.response.data.message || 'Server error',
        data: error.response.data,
      };
    } else if (error.request) {
      throw {
        status: null,
        message: 'No response received from the server',
      };
    } else {
      throw {
        status: null,
        message: `Error: ${error.message}`,
      };
    }
  }
};

export const getListings = async (pageNumber, resultsPerPage, Queries) => {
  const url = `${BASE_URL}/listings?pageNumber=${pageNumber}&resultsPerPage=${resultsPerPage}${Queries}`;
  return await fetchRequest(url);
};

export const getMapListings = async (polygon) => {
  const url = `${BASE_URL}/map?polygon=${polygon}`;
  return await fetchRequest(url);
};

export const getSingleListing = async (id) => {
  const url = `${BASE_URL}/listings/${id}`;
  return await fetchRequest(url);
};

export const getSimilarListings = async (id) => {
  const url = `${BASE_URL}/similar/${id}`;
  return await fetchRequest(url);
};

export const getSearchListings = async (search) => {
  const url = `${BASE_URL}/listings?search=${search}`;
  return await fetchRequest(url);
};

export const googleLogin = async (data) => {
  const url = `${BASE_URL}/auth/googleLogin`;
  return await fetchRequest(url, 'POST', data);
};

export const signInService = async (data) => {
  const url = `/auth/signup`;
  return await fetchRequest(url, 'POST', data)
}

export const loginService = async (data) => {
  const url = `/auth/login`;
  return await fetchRequest(url, 'POST', data)
}
