import './App.css';
import Main from './pages/main';
import Buypage from './pages/buyPage';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import PropertyView from './pages/propertyView';
import 'leaflet/dist/leaflet.css';
import Layout from './layout/layout';
import { FilterProvider } from './components/FilterContext';
import ContactUs from './pages/contactUs';
import OurTeamPage from './pages/ourteamPage';
import ContactUsPage2 from './pages/contactUspage-2';
import Buy2 from './pages/buy2';
import { AuthProvider } from './hooks/AuthContext';
import OurStory from './pages/ourStory';
import SellPage from './pages/sell';
import BlogPage from './pages/bolgPage';
import ProfilePage from './components/ProfilePage';
import Blog from "./pages/blog"
import Terms from './pages/terms';
import Privacy from './pages/privacy';
import HomeValuation from './pages/homeValuation';
import Accessibility1 from './pages/Accessibility/Accessibility1';
import Accessibility2 from './pages/Accessibility/Accessibility2';
import Accessibility3 from './pages/Accessibility/Accessibility3';
import Accessibility4 from './pages/Accessibility/Accessibility4';



function App() {

  const router = createBrowserRouter([
    {
      element: <Layout />,
      children: [
        {
          path: '/',
          element: <Main />
        },
        {
          path: '/buy2',
          element: <Buypage />,
        },
        {
          path: '/property/:id',
          element: <PropertyView />,
        },
        {
          path: '/contact2',
          element: <ContactUs />,
        },
        {
          path: '/ourteam',
          element: <OurTeamPage />,
        },
        {
          path: '/contact',
          element: <ContactUsPage2 />,
        },
        {
          path: '/buy',
          element: <Buy2 />,
        },
        {
          path: '/ourstory',
          element: <OurStory />
        },
        {
          path: '/sell',
          element: <SellPage />
        },
        {
          path: '/blog',
          element: <BlogPage />
        },

        {
          path:'/ourTeam/:name',
          element:<ProfilePage />
        },
        {
          path:'/ourstory/:name',
          element:<ProfilePage />
        },
        {
          path:'/neighborhoods/:key',
          element:<Blog/>
        },
        {
          path:'/buy/:name',
          element:<Buy2/>
        },
        {
          path:"/Accessibility",
          element:<Terms/>
        },
        {
          path:"/privacy-policy",
          element:<Privacy/>
        },
        {
          path:"/home-valuation",
          element:<HomeValuation/>
        },
        {
          path:"/Accessibility-for-Ontarians-with-Disabilities-Act",
          element:<Accessibility1/>
        },
        {
          path:"/Accessibility-for-Persons-with-Disabilities-Policy",
          element:<Accessibility2/>
        },
        {
          path:"/Integrated-Accessibility-Standards-Policy",
          element:<Accessibility3/>
        },
        {
          path:"/Multi-Year-Plan",
          element:<Accessibility4/>
        }

      



      ]

    },



  ])
  return (
    <div className="App">
      <AuthProvider>
        <FilterProvider>
          <RouterProvider router={router} />
        </FilterProvider>
      </AuthProvider>
    </div>
  );
}

export default App;
